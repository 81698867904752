import { Box, Typography } from "@mui/material";
import { TextInput } from "src/components/Inputs";
import { Field } from "./Field";

export class VolumetricRatio extends Field {
  value: number | undefined;

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Volumetric ratio", required);
    this.value = undefined;
  }

  public getValue(): number | undefined {
    return this.value;
  }

  public setValue(value: number | undefined): void {
    this.value = value;
  }

  private setValueAs(value: string): number | string {
    //coverting to number if possible
    if (!isNaN(Number(value))) {
      return +value;
    }
    return value;
  }

  private validateNumber(value: string | number): string | boolean {
    //validate if value is number
    if (isNaN(Number(value)) && value !== "" && value !== undefined) {
      return "Invalid value for type Number ";
    }
    return true;
  }
  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ flexShrink: 0, fontWeight: "bold" }}
        >
          1 cbm =
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <TextInput
            label={this.label}
            fieldName={this.fieldName}
            control={control}
            rules={{
              required: this.required && "Volumetric ratio is mandatory!",
              min: {
                value: 0,
                message: "Must be positive",
              },
              validate: this.validateNumber,
              setValueAs: this.setValueAs,
            }}
          />
        </Box>
      </Box>
    );
  }
}
