import RouteRestrictionComponent from "src/components/RouteRestriction";
import { Field } from "./Field";
import { optionsSave } from "src/components/RouteRestriction";

export class RouteRestriction extends Field {
  value: object[];

  constructor(fieldName: string = "", required: boolean = false) {
    super(fieldName, "Route Restriction", required);
    this.value = [{ routes: [], excluded: false }];
  }

  public getValue(): object[] {
    return this.value;
  }

  public setValue(value: any[], fromJson?: boolean): void {
    if (fromJson) {
      for (const v of value) {
        if (v.hasOwnProperty("included")) {
          v.excluded = !v.included;
          delete v.included;
        }
        v.filter = v.filter || v.addOnTop || [];
        if (v.hasOwnProperty("addOnTop")) {
          delete v.addOnTop;
        }
        for (const route of v.routes) {
          if (!optionsSave.find((o) => o.value === route.value)) {
            optionsSave.push(route);
          }
        }
      }
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return <RouteRestrictionComponent fieldName={this.fieldName} />;
  }
}
