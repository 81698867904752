import { Range } from "src/types";
import { Currency as CurrencyField, Field } from "../../Fields";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";
import { Entity } from "../Entity";

export class Currency extends CellEntity {
  private currency: CurrencyField;
  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Currency",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.currency = new CurrencyField("currency", true);
  }

  public getFields(): Field[] {
    return [this.currency];
  }

  public getNameField(control: any): React.ReactNode {
    return this.global ? super.getNameField(control) : null;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      scope: "global",
      value: [this.currency.getValue()],
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.currency.setValue(json.value[0]);
  }
}
