import moment from "moment";
import { Moment } from "moment";
import { DateInput } from "src/components/Inputs";
import { Field } from "./Field";

export class Validity extends Field {
  value: Moment | undefined;

  constructor(fieldName: string, label: string, required: boolean = false) {
    super(fieldName, label, required);
    this.value = undefined;
  }

  public getValue(): Moment | undefined {
    return this.value;
  }

  public setValue(value: Moment | string): void {
    if (typeof value === "string") {
      this.value = moment(value);
      return;
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <DateInput
        label={this.label}
        fieldName={this.fieldName}
        control={control}
        rules={{ required: this.required && `${this.label} is mandatory!` }}
      />
    );
  }
}
