import { useCallback, useState } from "react";
import { Range, States } from "src/types";
import { toast } from "react-toastify";
import { Field, RangeSelection } from "src/libs/Fields";

interface useSelectRangeProps {
  state: States;
  setState: React.Dispatch<React.SetStateAction<States>>;
}
const useSelectRange = ({ state, setState }: useSelectRangeProps) => {
  const [field, setField] = useState<Field>();
  const [prevState, setPrevState] = useState<States>();

  const startSelect = useCallback(
    (nfield: Field) => {
      if (field === nfield) {
        return;
      }
      toast.info("Please select range and press right click!");
      setField(nfield);
      setState("FIELDS");
      setPrevState(state);
    },
    [setField, setState, state, setPrevState, field]
  );

  const endSelect = useCallback(
    (
      sheetIndex: number,
      sheetName: string,
      range: Range,
      coordinate: string
    ) => {
      if (!field || !prevState) {
        throw new Error("Invalid state for select range");
      }
      field.setValue(new RangeSelection(range, coordinate));

      toast.success(`Selected range for ${field.label} is ${coordinate}`);
      setField(undefined);
      setState(prevState!);
      setPrevState(undefined);
    },
    [setField, setState, prevState, setPrevState, field]
  );

  return { startSelect, endSelect, field };
};

export default useSelectRange;
