import { ContainerType, Field } from "src/libs/Fields";
import { Range } from "src/types";
import { TableEntity } from "../../TableEntity";
import { GeneralFee } from "./GeneralFee";

export class GeneralFeeFCL extends GeneralFee {
  private container_type: ContainerType;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.container_type = new ContainerType("container_type", true);
  }

  public getFields(): Field[] {
    const res = super.getFields();
    res.splice(5, 0, this.container_type);
    return res;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      containerTypes: this.container_type
        .getValue()
        .map((value) => value.feeId),
    };
  }

  public fromJson(json: any, map: Map<string, any>): void {
    super.fromJson(json, map);
    this.id = json.id.split("$%$")[0];
    this.container_type.setValue(
      json.containerType ?? json.containerTypes ?? [],
      true
    );
  }
}
