import FieldIconSelect from "src/components/FieldIconSelect";
import { TextInput } from "src/components/Inputs";
import { Field, RangeSelection } from "./Field";

export class FeeCode extends Field {
  value: string | undefined | RangeSelection;
  private rangeSelection: boolean;

  constructor(
    fieldName: string,
    required: boolean = false,
    rangeSelection: boolean = false
  ) {
    super(fieldName, "Fee Code", required);
    this.value = undefined;
    this.rangeSelection = rangeSelection;
  }

  public getValue(): string | undefined | RangeSelection {
    return this.value;
  }

  public setValue(value: string | undefined | RangeSelection): void {
    if (
      this.value instanceof RangeSelection &&
      value === this.value.coordinate
    ) {
      return; // No change
    }
    this.value = value;
  }
  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <TextInput
        label={this.label}
        fieldName={this.fieldName}
        control={control}
        rules={{ required: this.required && "Fee Code is mandatory!" }}
        InputProps={{
          endAdornment: this.rangeSelection ? (
            <FieldIconSelect field={this} />
          ) : undefined,
        }}
      />
    );
  }
}
