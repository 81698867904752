import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { MuiFileInput } from "mui-file-input";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Logo from "src/components/Logo";

const EXCEL_TO_JSON_URL = process.env.REACT_APP_EXCEL_TO_JSON_URL ?? "";

type FileFormProps = {
  setWorkBookData: React.Dispatch<unknown>;
  setFile: React.Dispatch<React.SetStateAction<string | undefined>>;
};

/**
 * Component for rendering a form to upload a file and handle the submission.
 * @param setWorkBookData - Function to set the workbook data after successful file upload.
 */

type FormValues = {
  file: File | null;
};
const FileForm: React.FC<FileFormProps> = ({ setWorkBookData, setFile }) => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      file: null,
    },
  });

  const { isSubmitting } = formState;

  const onSubmit = async (data: FormValues) => {
    try {
      const formData = new FormData();
      formData.append("file", data.file!);
      // Send a POST request to convert the uploaded Excel file to JSON
      const response = await axios.post(EXCEL_TO_JSON_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      //adding the file name to the info object
      response.data.info.name = data.file!.name;
      console.log("response", response.data);
      setWorkBookData(response.data);
      setFile(await convertFileToBase64(data.file!));
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(
        <Box>
          <Typography variant="subtitle1">File upload failed!</Typography>
          <Typography variant="body2">
            try to open the file in excel and try again.
          </Typography>
        </Box>,
        {
          position: "top-center",
        }
      );
    }
  };

  const validateFile = (file: File | null) => {
    if (file === null || file === undefined || file.name === undefined) {
      return "Invalid file";
    }
    const fileType = file.name.split(".").pop();
    if (fileType === undefined || fileType.toLowerCase() !== "xlsx") {
      return "Invalid file type";
    }
    return true;
  };

  const handleDrop = (e: any, field: any) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      field.onChange(files[0]);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Logo width={203} height={60} />
      </Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 1,
          borderColor: "#CBD6E0",
          width: 0.5,
          height: 0.6,
          display: "flex",
          minWidth: "530px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Upload a file
          </Typography>
          <Typography variant="body1">
            Upload or drag and drop a XLSX file start tagging
          </Typography>
          <Controller
            name="file"
            control={control}
            rules={{ required: "File is invalid", validate: validateFile }}
            render={({ field, fieldState }) => (
              <MuiFileInput
                id="file-upload-input"
                placeholder=".xlsx"
                {...field}
                helperText={fieldState.invalid ? fieldState.error?.message : ""}
                error={fieldState.invalid}
                InputProps={{
                  inputProps: {
                    accept: ".xlsx",
                  },
                }}
                onDrop={(e) => handleDrop(e, field)}
                onDragOver={handleDragOver}
              />
            )}
          />
          <LoadingButton
            disabled={isSubmitting}
            type="submit"
            loading={isSubmitting}
            variant="contained"
            id="file-upload-button"
            color="primary"
            sx={{ mt: 2, width: 0.3 }}
          >
            {isSubmitting ? "Uploading..." : "Submit"}
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default FileForm;

async function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(
        (reader.result as string).replace(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          ""
        )
      );
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}
