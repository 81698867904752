import { Range } from "src/types";
import React from "react";
import { TextInput } from "src/components/Inputs";

export abstract class Entity {
  id: string;
  range: Range;
  type: string;
  sheetIndex: number;
  coordinate: string;
  sheetName: string;
  name: string;
  status: "ERROR" | "OK" | "IGNORE";

  public static FILE_TYPE = "FCL";

  constructor(
    id: string,
    range: Range,
    type: string,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE"
  ) {
    this.id = id;
    this.range = range;
    this.type = type;
    this.sheetIndex = sheetIndex;
    this.coordinate = coordinate;
    this.sheetName = sheetName;
    this.name = name;
    this.status = status;
  }

  public getName(): string {
    return this.coordinate;
  }

  public getNameField(control: any): React.ReactNode {
    return (
      <TextInput
        fieldName="name"
        label="Name"
        rules={{ required: "Name is mandatory!" }}
        control={control}
      />
    );
  }

  public static Mapper: Record<string, string> = {
    POL: "pol",
    POD: "pod",
    "Validity start": "validityStart",
    "Validity end": "validityEnd",
    "General Fee": "fee",
    "Free Days(POL)": "freeDaysPol",
    "Free Days(POD)": "freeDaysPod",
    "Volumetric Ratio": "volumetricRatio",
    Condition: "condition",
    Currency: "currency",
    Hazardous: "hazardous",
    "OCEAN Fee": "oceanFee",
    "AirFreight Fee": "oceanFee",
    Ignore: "ignoreCells",
    "Override Value": "overrideCells",
    Comment: "comment",
    Range: "range",
    Service: "service",
    "Transit time": "transitTime",
    VIA: "via",
    Transshipment: "transshipment",
    "Routes Table": "routes",
  };

  public static get overrideOptions(): string[] {
    const options = [
      "Condition",
      "Currency",
      "POL",
      "POD",
      "Validity start",
      "Validity end",
      "General Fee",
      "Free Days(POL)",
      "Free Days(POD)",
      "Service",
      "VIA",
      "Transit Time",
      "Comment",
      "Hazardous",
    ];
    if (Entity.FILE_TYPE === "FCL") {
      options.push("OCEAN Fee");
    }
    if (Entity.FILE_TYPE === "AIR") {
      options.push("AirFreight Fee", "Volumetric Ratio", "Range");
    }
    if (Entity.FILE_TYPE === "LCL") {
      options.push("OCEAN Fee", "Volumetric Ratio");
    }
    return options;
  }

  public abstract fromJson(json: any, map: Map<string, Entity>): void;
}
