import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import { Converter } from "src/libs/Entities";
import useEntities from "./EntitiesContext";

const API_URL = process.env.REACT_APP_API_URL ?? "";
const VAL_API_URL = process.env.REACT_APP_VAL_API_URL ?? "";

interface SaveEntitiesBtnProps {
  file: string;
  handleValidationErrors: (errors: any) => void;
  fileName: string;
}
const SaveEntitiesBtn: React.FC<SaveEntitiesBtnProps> = ({
  file,
  handleValidationErrors,
  fileName,
}) => {
  const { fileData, tableEntities, globalEntities } = useEntities();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = Converter.toJson(tableEntities, globalEntities);

      toast.promise(
        axios.post(`${API_URL}/document`, {
          name: fileData.fileName,
          document: {
            data,
            metadata: fileData,
          },
        }),
        {
          success: "Saved successfully!",
        }
      );

      const response = await axios.post(`${VAL_API_URL}/process`, {
        name: fileData.fileName,
        document: {
          data,
          metadata: fileData,
        },
        file_content: file,
        fileName: fileName,
      });
      handleValidationErrors(response.data.errors);
      if (response.data.file_content) {
        const base64Content = response.data.file_content;
        const fileName = `WI_${fileData.fileName.replace(".xlsx", "")}.xlsx`;
        downloadBase64File(base64Content, fileName);
      }
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      size="small"
      variant="outlined"
      loading={loading}
      onClick={onSubmit}
      sx={{ fontFamily: "Hanken Grotesk", fontWeight: 600 }}
    >
      Run Process
    </LoadingButton>
  );
};

export default SaveEntitiesBtn;

function downloadBase64File(base64String: string, fileName: string) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a link element and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
