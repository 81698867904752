import { IconButton, InputAdornment } from "@mui/material";
import React from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import useEntities from "src/dashboard/EntitiesContext";
import { Field } from "src/libs/Fields";

const FieldIconSelect = ({ field }: { field: Field }) => {
  const { startSelect } = useEntities();
  return (
    <InputAdornment position="start" title="Select Range">
      <IconButton onClick={() => startSelect(field)} edge="end">
        <CropFreeIcon sx={{ fontSize: "1rem" }} />
      </IconButton>
    </InputAdornment>
  );
};

export default FieldIconSelect;
