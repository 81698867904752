import React, { createContext, useContext } from "react";
import useUpdateCellEntity from "src/hooks/useUpdateCellEntity";
import { CellEntity, Entity, Ignore, TableEntity } from "src/libs/Entities";
import useUpdateTableEntity from "src/hooks/useUpdateTableEntity";
import { States } from "src/types";
import { Field } from "src/libs/Fields";

interface EntitiesContextProps {
  globalEntities: CellEntity[];
  CellManage: ReturnType<typeof useUpdateCellEntity>;
  handleAddCellEntitySubmit: (entity: CellEntity) => void;
  handleEditCellEntity: (entity: CellEntity, range?: boolean) => void;
  handleDeleteCellEntity: (entity: CellEntity) => void;
  handleCopyCellEntity: (entity: CellEntity) => void;
  tableEntities: TableEntity[];
  TableManage: ReturnType<typeof useUpdateTableEntity>;
  handleAddTableEntitySubmit: (entity: TableEntity) => void;
  handleEditTableEntity: (entity: TableEntity, range?: boolean) => void;
  handleDeleteTableEntity: (entity: TableEntity, ask?: boolean) => void;
  state: States;
  setState: React.Dispatch<React.SetStateAction<States>>;
  fileData: any;
  handleShowEntity: (entity: Entity) => void;
  startSelect: (nfield: Field) => void;
  field?: Field;
  handleDeleteIgnoreEntity: (entity: Ignore) => void;
}

const EntitiesContext = createContext<EntitiesContextProps>({
  globalEntities: [],
  CellManage: {
    entity: undefined,
    beginUpdate: () => {},
    submitUpdate: () => {},
    revertChanges: () => {},
    changeType: () => {},
  },
  handleAddCellEntitySubmit: () => {},
  handleEditCellEntity: () => {},
  handleDeleteCellEntity: () => {},
  handleCopyCellEntity: () => {},
  tableEntities: [],
  TableManage: {
    entity: undefined,
    beginUpdate: () => {},
    submitUpdate: () => {},
    revertChanges: () => {},
  },
  handleAddTableEntitySubmit: () => {},
  handleEditTableEntity: () => {},
  handleDeleteTableEntity: () => {},
  state: "NORMAL",
  setState: () => {},
  fileData: undefined,
  handleShowEntity: () => {},
  startSelect: () => {},
  field: undefined,
  handleDeleteIgnoreEntity: () => {},
});

interface EntitiesProviderProps {
  children: React.ReactNode;
  value: EntitiesContextProps;
}

export const EntitiesProvider: React.FC<EntitiesProviderProps> = ({
  children,
  value,
}) => {
  return (
    <EntitiesContext.Provider value={value}>
      {children}
    </EntitiesContext.Provider>
  );
};

const useEntities = () => {
  return useContext(EntitiesContext);
};

export default useEntities;
