import React, { useMemo } from "react";
import useEntities from "src/dashboard/EntitiesContext";
import { SelectInput, SelectInputProps } from "./Inputs";
import { CellEntity, GeneralFee } from "src/libs/Entities";

const SelectFees: React.FC<SelectInputProps> = (props) => {
  const { globalEntities, tableEntities, CellManage } = useEntities();

  const options = useMemo(() => {
    const options: CellEntity[] = globalEntities.filter(
      (entity) => entity instanceof GeneralFee
    );
    for (const table of tableEntities) {
      options.push(...table.getFees());
    }
    return options.sort((a, b) => {
      if (a.sheetIndex === CellManage.entity?.sheetIndex) return -1;
      if (b.sheetIndex === CellManage.entity?.sheetIndex) return 1;
      return a.sheetIndex - b.sheetIndex;
    });
  }, [globalEntities, tableEntities, CellManage]);

  return (
    <SelectInput
      {...props}
      options={options}
      isOptionEqualToValue={(option: CellEntity, value: any) =>
        option?.id === value || option?.id === value?.id || option === value
      }
      getOptionLabel={(option: any) =>
        option instanceof CellEntity
          ? option.getName()
          : options.find((o) => o.id === option)?.getName() ?? ""
      }
      groupBy={(option: any) =>
        option instanceof CellEntity
          ? option.sheetName
          : options.find((o) => o.id === option)?.sheetName ?? ""
      }
      getOptionKey={(option: any) => option?.id ?? option}
    />
  );
};

export default SelectFees;
