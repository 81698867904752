import { SelectInput } from "src/components/Inputs";
import { Field } from "./Field";

export type ContainerTypeValue = {
  label: string;
  conditionId: string;
  conditionValue: string;
  feeId: string;
};

export class ContainerType extends Field {
  value: ContainerTypeValue[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Container Type", required);
    this.value = [];
  }

  public getValue(): ContainerTypeValue[] {
    return this.value;
  }

  public setValue(
    value: ContainerTypeValue[] | string[],
    fromJson?: boolean
  ): void {
    if (fromJson) {
      this.value = value.map(
        (v) =>
          ContainerType.options.find(
            (o) =>
              o.label === v ||
              o.conditionId === v ||
              o.conditionValue === v ||
              o.feeId === v
          ) as ContainerTypeValue
      );
      return;
    }
    this.value = value as ContainerTypeValue[];
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    const onChange = (e: any, value: ContainerTypeValue[]) => {
      const rate = getValues("rate_basis");
      if ((!value || value.length === 0) && !rate) {
        setValue("comment", "");
        return;
      }
      let ctlabel = "";
      if (value.length === 1) {
        ctlabel = value[0].label.replace("Container", "");
      }
      if (
        value.length === 2 &&
        value.find((v) => v.label === "40 Container") &&
        value.find((v) => v.label === "40 HC Container")
      ) {
        ctlabel = " 40 / HC";
      }
      if (rate?.label === "Per Container") {
        setValue("comment", rate?.label + " " + ctlabel, {
          shouldValidate: true,
        });
      }
    };

    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={ContainerType.options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        control={control}
        rules={{ required: this.required && "Container Type is mandatory!" }}
        isMulti
        checkBoxs
        onChange={onChange}
      />
    );
  }

  public static options: ContainerTypeValue[] = [
    {
      label: "20 Container",
      conditionId: "Container_20",
      conditionValue: "TWENTY",
      feeId: "OCEAN_20",
    },
    {
      label: "40 Container",
      conditionId: "Container_40",
      conditionValue: "FORTY",
      feeId: "OCEAN_40",
    },
    {
      label: "40 HC Container",
      conditionId: "Container_40_HC",
      conditionValue: "FORTY_HC",
      feeId: "OCEAN_40_HC",
    },
    {
      label: "45 HC Container",
      conditionId: "Container_45_HC",
      conditionValue: "FORTY_FIVE_HC",
      feeId: "OCEAN_45_HC",
    },
    {
      label: "20 OT Container",
      conditionId: "Container_20_OT",
      conditionValue: "TWENTY_OPEN_TOP",
      feeId: "OCEAN_20_OT",
    },
    {
      label: "40 OT Container",
      conditionId: "Container_40_OT",
      conditionValue: "FORTY_OPEN_TOP",
      feeId: "OCEAN_40_OT",
    },
    {
      label: "20 Reefer Container",
      conditionId: "Container_20_Reefer",
      conditionValue: "TWENTY_REEFER",
      feeId: "OCEAN_20_Reefer",
    },
    {
      label: "40 Reefer Container",
      conditionId: "Container_40_Reefer",
      conditionValue: "FORTY_REEFER",
      feeId: "OCEAN_40_Reefer",
    },
    {
      label: "20 FR Container",
      conditionId: "Container_20_FR",
      conditionValue: "TWENTY_FLAT_RACK",
      feeId: "OCEAN_20_FR",
    },
    {
      label: "40 FR Container",
      conditionId: "Container_40_FR",
      conditionValue: "FORTY_FLAT_RACK",
      feeId: "OCEAN_40_FR",
    },
    {
      label: "RORO",
      conditionId: "Container_RORO",
      conditionValue: "RORO",
      feeId: "OCEAN_RORO",
    },
  ];
}
