import React from "react";
import { Box, Typography, LinearProgress, SxProps } from "@mui/material";

// Define the prop types for the ProgressBar component
type ProgressBarProps = {
  value: number;
  maxValue: number;
  sx?: SxProps;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ value, maxValue, sx }) => {
  // Calculate the percentage value
  const percentage = (value / maxValue) * 100;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 1,
        height: "100%",
        ...sx,
      }}
    >
      <Box width={1} m={5}>
        <LinearProgress variant="determinate" value={percentage} />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <Typography variant="h6">
            {`Loaded sheets ${value}/${maxValue}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
