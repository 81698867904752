import SelectWithArgsInput from "src/components/SelectWithArgsInput";
import { Field } from "./Field";
import { Fees } from "./Fees";
import { Min } from "./Min";
import { Max } from "./Max";
import { PrecentFrom } from "./PrecentFrom";
import { PrecentType } from "./PrecentType";
import { UnitWeight } from "./UnitWeight";
import { ContainerType } from "./ContainerType";

export class RateBasis extends Field {
  value: any | null;

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Rate Basis", required);
    this.value = null;
  }

  public getValue(): any | null {
    return this.value;
  }

  public setValue(value: any | null, fromJson?: boolean): void {
    if (fromJson && value) {
      //converting container type from asaf types
      const option = this.options.find((o) => o.key === (value as any).key);
      option.value = {};
      for (const f of option.fields) {
        try {
          f.setValue((value as any).value[f.fieldName]);
          option.value[f.fieldName] = f.getValue();
        } catch (e) {
          //Ignore
        }
      }
      option.value = Object.fromEntries(
        Object.entries(option.value).filter(
          ([key, value]: any) => value || false
        )
      );
      this.value = option;
      return;
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    const onChange = (e: any, value: any) => {
      const ct = getValues("container_type");
      if (!value) {
        setValue("comment", "");
        return;
      }
      if (
        ct &&
        (value.label === "Per TEU" ||
          value.label === "Per Shipment" ||
          value.label === "Per BL")
      ) {
        setValue("container_type", [
          ContainerType.options.find((o) => o.label === "20 Container"),
          ContainerType.options.find((o) => o.label === "40 Container"),
          ContainerType.options.find((o) => o.label === "40 HC Container"),
        ]);
      }
      let ctlabel = "";
      if (ct?.length === 1) {
        ctlabel = ct[0].label.replace("Container", "");
      }
      if (
        ct?.length === 2 &&
        ct.find((v: any) => v.label === "40 Container") &&
        ct.find((v: any) => v.label === "40 HC Container")
      ) {
        ctlabel = "40 / HC";
      }
      setValue("comment", value?.label + " " + ctlabel, {
        shouldValidate: true,
      });
    };

    return (
      <SelectWithArgsInput
        label={this.label}
        fieldName={this.fieldName}
        options={this.options}
        onChange={onChange}
      />
    );
  }

  public get options(): any[] {
    switch (Field.FILE_TYPE) {
      case "FCL":
        return this.FCL_options;
      case "LCL":
        return this.LCL_options;
      case "AIR":
        return this.AIR_options;
      default:
        return [];
    }
  }

  private FCL_options = [
    {
      key: "Per Container",
      feeType: "PER_PIECE",
      label: "Per Container",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per TEU",
      feeType: "PER_TEU",
      label: "Per TEU",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Shipment",
      feeType: "PER_SHIPMENT",
      label: "Per Shipment",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per BL",
      feeType: "PER_SHIPMENT",
      label: "Per BL",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Percent",
      feeType: "PERCENT",
      label: "Percent",
      parent: this.fieldName,
      fields: [
        new Fees("precentFeeIds", true),
        new Min("min", false),
        new Max("max", false),
        new PrecentFrom("precentFrom", false),
        new PrecentType("precentType", false),
      ],
    },
  ];

  private LCL_options = [
    {
      key: "Per W/M",
      feeType: "PER_WEIGHT_OR_MEASUREMENT",
      label: "Per W/M",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Piece",
      feeType: "PER_PIECE",
      label: "Per Piece",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Gross Weight",
      feeType: "PER_GROSS_WEIGHT",
      label: "Per Gross Weight",
      parent: this.fieldName,
      fields: [new UnitWeight("unit", true)],
    },
    {
      key: "Percent",
      feeType: "PERCENT",
      label: "Percent",
      parent: this.fieldName,
      fields: [
        new Fees("precentFeeIds", true),
        new Min("min", false),
        new Max("max", false),
        new PrecentFrom("precentFrom", false),
        new PrecentType("precentType", false),
      ],
    },
  ];

  private AIR_options = [
    {
      key: "Per C/W",
      feeType: "PER_CHARGEABLE_WEIGHT",
      label: "Per C/W",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Piece",
      feeType: "PER_PIECE",
      label: "Per Piece",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Shipment",
      feeType: "PER_SHIPMENT",
      label: "Per Shipment",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per BL",
      feeType: "PER_SHIPMENT",
      label: "Per BL",
      parent: this.fieldName,
      fields: [],
    },
    {
      key: "Per Gross Weight",
      feeType: "PER_GROSS_WEIGHT",
      label: "Per Gross Weight",
      parent: this.fieldName,
      fields: [new UnitWeight("unit", true)],
    },
    {
      key: "Percent",
      feeType: "PERCENT",
      label: "Percent",
      parent: this.fieldName,
      fields: [
        new Fees("precentFeeIds", true),
        new Min("min", false),
        new Max("max", false),
        new PrecentFrom("precentFrom", false),
        new PrecentType("precentType", false),
      ],
    },
  ];
}
