import { Field, InclusiveFrom, InclusiveTo } from "src/libs/Fields";
import { Range as RangeExcel } from "src/types";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";
import { Entity } from "../Entity";

export class Range extends CellEntity {
  private inclusive_to: InclusiveTo;
  private inclusive_from: InclusiveFrom;

  constructor(
    id: string,
    range: RangeExcel,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Range",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      false,
      parentTable,
      override
    );
    this.inclusive_to = new InclusiveTo("inclusive_to");
    this.inclusive_from = new InclusiveFrom("inclusive_from");
  }

  public getFields(): Field[] {
    return [this.inclusive_to, this.inclusive_from];
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      inclusiveTo: this.inclusive_to.getValue(),
      inclusiveFrom: this.inclusive_from.getValue(),
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.inclusive_to.setValue(json.inclusiveTo);
    this.inclusive_from.setValue(json.inclusiveFrom);
  }

  public getNameField(control: any): React.ReactNode {
    return null;
  }
}
