import React, { useEffect, useState } from "react";

import {
  Box,
  debounce,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";

import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import axios from "axios";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import useEntities from "src/dashboard/EntitiesContext";
import { SelectInput } from "./Inputs";

const API_URL = process.env.REACT_APP_API_ENUMS_URL ?? "";
const TOKEN = process.env.REACT_APP_WISOR_TOKEN ?? "";

const RouteRestriction = ({ fieldName }: { fieldName: string }) => {
  const { fileData } = useEntities();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>(optionsSave);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ routes: [], excluded: false, filter: [] });
    }
  }, [fields, append]);

  const getOptions = (value: any) => {
    const ports = value.results.airports.map((item: any) => ({
      label: item.formatted_address,
      value: item.port_code,
    }));
    const ports2 = value.results.seaports.map((item: any) => ({
      label: item.formatted_address,
      value: item.port_code,
    }));
    return [...ports, ...ports2];
  };

  //debounce the input to prevent too many requests
  const handleInputChange = debounce(
    (event: React.SyntheticEvent, value: string, reason: string) => {
      if (value && value.length >= 2) {
        (async () => {
          setLoading(true);
          try {
            const response = await axios.get(
              `${API_URL}${`/port?free_text=${value}&quote_method=${fileData.loadType}`}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                },
              }
            );
            const options = getOptions(response.data);
            optionsSave = options
              .concat(optionsSave)
              .filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
              );
            setOptions(optionsSave);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        })();
      }
    },
    500
  );

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <SelectInput
            isMulti
            options={options}
            loading={loading}
            getOptionLabel={(option) => option.label}
            getOptionKey={(option) => option.value}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            control={control}
            checkBoxs
            fieldName={`${fieldName}.${index}.routes`}
            label="Route Restrictions"
            onInputChange={handleInputChange}
          />
          <FormControl component="fieldset">
            <Controller
              name={`${fieldName}.${index}.excluded`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  onChange={(e, val) => {
                    field.onChange(val === "true");
                  }}
                  value={Boolean(field.value).toString()}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Excluded"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Included"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {/* <CheckBoxInput
            control={control}
            fieldName={`${fieldName}.${index}.excluded`}
            label="Excluded"
          /> */}
          <SelectInput
            control={control}
            options={["POL", "POD", "TS"]}
            fieldName={`${fieldName}.${index}.filter`}
            label="Filter"
            isMulti
          />
        </React.Fragment>
      ))}
      {
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => remove(fields.length - 1)}
            disabled={fields.length <= 1}
          >
            <PlaylistRemoveIcon
              color={fields.length === 1 ? undefined : "error"}
            />
          </IconButton>
          <IconButton onClick={() => append({ tags: [] })}>
            <PlaylistAddIcon color="success" />
          </IconButton>
        </Box>
      }
    </>
  );
};

export let optionsSave = [
  { label: "Afghanistan", value: "AF", country: true },
  { label: "Åland Islands", value: "AX", country: true },
  { label: "Albania", value: "AL", country: true },
  { label: "Algeria", value: "DZ", country: true },
  { label: "American Samoa", value: "AS", country: true },
  { label: "Andorra", value: "AD", country: true },
  { label: "Angola", value: "AO", country: true },
  { label: "Anguilla", value: "AI", country: true },
  { label: "Antarctica", value: "AQ", country: true },
  { label: "Antigua and Barbuda", value: "AG", country: true },
  { label: "Argentina", value: "AR", country: true },
  { label: "Armenia", value: "AM", country: true },
  { label: "Aruba", value: "AW", country: true },
  { label: "Australia", value: "AU", country: true },
  { label: "Austria", value: "AT", country: true },
  { label: "Azerbaijan", value: "AZ", country: true },
  { label: "Bahamas", value: "BS", country: true },
  { label: "Bahrain", value: "BH", country: true },
  { label: "Bangladesh", value: "BD", country: true },
  { label: "Barbados", value: "BB", country: true },
  { label: "Belarus", value: "BY", country: true },
  { label: "Belgium", value: "BE", country: true },
  { label: "Belize", value: "BZ", country: true },
  { label: "Benin", value: "BJ", country: true },
  { label: "Bermuda", value: "BM", country: true },
  { label: "Bhutan", value: "BT", country: true },
  { label: "Bolivia, Plurinational State of", value: "BO", country: true },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ", country: true },
  { label: "Bosnia and Herzegovina", value: "BA", country: true },
  { label: "Botswana", value: "BW", country: true },
  { label: "Bouvet Island", value: "BV", country: true },
  { label: "Brazil", value: "BR", country: true },
  { label: "British Indian Ocean Territory", value: "IO", country: true },
  { label: "Brunei Darussalam", value: "BN", country: true },
  { label: "Bulgaria", value: "BG", country: true },
  { label: "Burkina Faso", value: "BF", country: true },
  { label: "Burundi", value: "BI", country: true },
  { label: "Cambodia", value: "KH", country: true },
  { label: "Cameroon", value: "CM", country: true },
  { label: "Canada", value: "CA", country: true },
  { label: "Cape Verde", value: "CV", country: true },
  { label: "Cayman Islands", value: "KY", country: true },
  { label: "Central African Republic", value: "CF", country: true },
  { label: "Chad", value: "TD", country: true },
  { label: "Chile", value: "CL", country: true },
  { label: "China", value: "CN", country: true },
  { label: "Christmas Island", value: "CX", country: true },
  { label: "Cocos (Keeling) Islands", value: "CC", country: true },
  { label: "Colombia", value: "CO", country: true },
  { label: "Comoros", value: "KM", country: true },
  { label: "Congo", value: "CG", country: true },
  {
    label: "Congo, the Democratic Republic of the",
    value: "CD",
    country: true,
  },
  { label: "Cook Islands", value: "CK", country: true },
  { label: "Costa Rica", value: "CR", country: true },
  { label: "Côte d'Ivoire", value: "CI", country: true },
  { label: "Croatia", value: "HR", country: true },
  { label: "Cuba", value: "CU", country: true },
  { label: "Curaçao", value: "CW", country: true },
  { label: "Cyprus", value: "CY", country: true },
  { label: "Czech Republic", value: "CZ", country: true },
  { label: "Denmark", value: "DK", country: true },
  { label: "Djibouti", value: "DJ", country: true },
  { label: "Dominica", value: "DM", country: true },
  { label: "Dominican Republic", value: "DO", country: true },
  { label: "Ecuador", value: "EC", country: true },
  { label: "Egypt", value: "EG", country: true },
  { label: "El Salvador", value: "SV", country: true },
  { label: "Equatorial Guinea", value: "GQ", country: true },
  { label: "Eritrea", value: "ER", country: true },
  { label: "Estonia", value: "EE", country: true },
  { label: "Ethiopia", value: "ET", country: true },
  { label: "Falkland Islands (Malvinas)", value: "FK", country: true },
  { label: "Faroe Islands", value: "FO", country: true },
  { label: "Fiji", value: "FJ", country: true },
  { label: "Finland", value: "FI", country: true },
  { label: "France", value: "FR", country: true },
  { label: "French Guiana", value: "GF", country: true },
  { label: "French Polynesia", value: "PF", country: true },
  { label: "French Southern Territories", value: "TF", country: true },
  { label: "Gabon", value: "GA", country: true },
  { label: "Gambia", value: "GM", country: true },
  { label: "Georgia", value: "GE", country: true },
  { label: "Germany", value: "DE", country: true },
  { label: "Ghana", value: "GH", country: true },
  { label: "Gibraltar", value: "GI", country: true },
  { label: "Greece", value: "GR", country: true },
  { label: "Greenland", value: "GL", country: true },
  { label: "Grenada", value: "GD", country: true },
  { label: "Guadeloupe", value: "GP", country: true },
  { label: "Guam", value: "GU", country: true },
  { label: "Guatemala", value: "GT", country: true },
  { label: "Guernsey", value: "GG", country: true },
  { label: "Guinea", value: "GN", country: true },
  { label: "Guinea-Bissau", value: "GW", country: true },
  { label: "Guyana", value: "GY", country: true },
  { label: "Haiti", value: "HT", country: true },
  { label: "Heard Island and McDonald Islands", value: "HM", country: true },
  { label: "Holy See (Vatican City State)", value: "VA", country: true },
  { label: "Honduras", value: "HN", country: true },
  { label: "Hong Kong", value: "HK", country: true },
  { label: "Hungary", value: "HU", country: true },
  { label: "Iceland", value: "IS", country: true },
  { label: "India", value: "IN", country: true },
  { label: "Indonesia", value: "ID", country: true },
  { label: "Iran, Islamic Republic of", value: "IR", country: true },
  { label: "Iraq", value: "IQ", country: true },
  { label: "Ireland", value: "IE", country: true },
  { label: "Isle of Man", value: "IM", country: true },
  { label: "Israel", value: "IL", country: true },
  { label: "Italy", value: "IT", country: true },
  { label: "Jamaica", value: "JM", country: true },
  { label: "Japan", value: "JP", country: true },
  { label: "Jersey", value: "JE", country: true },
  { label: "Jordan", value: "JO", country: true },
  { label: "Kazakhstan", value: "KZ", country: true },
  { label: "Kenya", value: "KE", country: true },
  { label: "Kiribati", value: "KI", country: true },
  {
    label: "Korea, Democratic People's Republic of",
    value: "KP",
    country: true,
  },
  { label: "Korea, Republic of", value: "KR", country: true },
  { label: "Kuwait", value: "KW", country: true },
  { label: "Kyrgyzstan", value: "KG", country: true },
  { label: "Lao People's Democratic Republic", value: "LA", country: true },
  { label: "Latvia", value: "LV", country: true },
  { label: "Lebanon", value: "LB", country: true },
  { label: "Lesotho", value: "LS", country: true },
  { label: "Liberia", value: "LR", country: true },
  { label: "Libya", value: "LY", country: true },
  { label: "Liechtenstein", value: "LI", country: true },
  { label: "Lithuania", value: "LT", country: true },
  { label: "Luxembourg", value: "LU", country: true },
  { label: "Macao", value: "MO", country: true },
  {
    label: "Macedonia, the Former Yugoslav Republic of",
    value: "MK",
    country: true,
  },
  { label: "Madagascar", value: "MG", country: true },
  { label: "Malawi", value: "MW", country: true },
  { label: "Malaysia", value: "MY", country: true },
  { label: "Maldives", value: "MV", country: true },
  { label: "Mali", value: "ML", country: true },
  { label: "Malta", value: "MT", country: true },
  { label: "Marshall Islands", value: "MH", country: true },
  { label: "Martinique", value: "MQ", country: true },
  { label: "Mauritania", value: "MR", country: true },
  { label: "Mauritius", value: "MU", country: true },
  { label: "Mayotte", value: "YT", country: true },
  { label: "Mexico", value: "MX", country: true },
  { label: "Micronesia, Federated States of", value: "FM", country: true },
  { label: "Moldova, Republic of", value: "MD", country: true },
  { label: "Monaco", value: "MC", country: true },
  { label: "Mongolia", value: "MN", country: true },
  { label: "Montenegro", value: "ME", country: true },
  { label: "Montserrat", value: "MS", country: true },
  { label: "Morocco", value: "MA", country: true },
  { label: "Mozambique", value: "MZ", country: true },
  { label: "Myanmar", value: "MM", country: true },
  { label: "Namibia", value: "NA", country: true },
  { label: "Nauru", value: "NR", country: true },
  { label: "Nepal", value: "NP", country: true },
  { label: "Netherlands", value: "NL", country: true },
  { label: "New Caledonia", value: "NC", country: true },
  { label: "New Zealand", value: "NZ", country: true },
  { label: "Nicaragua", value: "NI", country: true },
  { label: "Niger", value: "NE", country: true },
  { label: "Nigeria", value: "NG", country: true },
  { label: "Niue", value: "NU", country: true },
  { label: "Norfolk Island", value: "NF", country: true },
  { label: "Northern Mariana Islands", value: "MP", country: true },
  { label: "Norway", value: "NO", country: true },
  { label: "Oman", value: "OM", country: true },
  { label: "Pakistan", value: "PK", country: true },
  { label: "Palau", value: "PW", country: true },
  { label: "Palestine, State of", value: "PS", country: true },
  { label: "Panama", value: "PA", country: true },
  { label: "Papua New Guinea", value: "PG", country: true },
  { label: "Paraguay", value: "PY", country: true },
  { label: "Peru", value: "PE", country: true },
  { label: "Philippines", value: "PH", country: true },
  { label: "Pitcairn", value: "PN", country: true },
  { label: "Poland", value: "PL", country: true },
  { label: "Portugal", value: "PT", country: true },
  { label: "Puerto Rico", value: "PR", country: true },
  { label: "Qatar", value: "QA", country: true },
  { label: "Réunion", value: "RE", country: true },
  { label: "Romania", value: "RO", country: true },
  { label: "Russian Federation", value: "RU", country: true },
  { label: "Rwanda", value: "RW", country: true },
  { label: "Saint Barthélemy", value: "BL", country: true },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
    country: true,
  },
  { label: "Saint Kitts and Nevis", value: "KN", country: true },
  { label: "Saint Lucia", value: "LC", country: true },
  { label: "Saint Martin (French part)", value: "MF", country: true },
  { label: "Saint Pierre and Miquelon", value: "PM", country: true },
  { label: "Saint Vincent and the Grenadines", value: "VC", country: true },
  { label: "Samoa", value: "WS", country: true },
  { label: "San Marino", value: "SM", country: true },
  { label: "Sao Tome and Principe", value: "ST", country: true },
  { label: "Saudi Arabia", value: "SA", country: true },
  { label: "Senegal", value: "SN", country: true },
  { label: "Serbia", value: "RS", country: true },
  { label: "Seychelles", value: "SC", country: true },
  { label: "Sierra Leone", value: "SL", country: true },
  { label: "Singapore", value: "SG", country: true },
  { label: "Sint Maarten (Dutch part)", value: "SX", country: true },
  { label: "Slovakia", value: "SK", country: true },
  { label: "Slovenia", value: "SI", country: true },
  { label: "Solomon Islands", value: "SB", country: true },
  { label: "Somalia", value: "SO", country: true },
  { label: "South Africa", value: "ZA", country: true },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
    country: true,
  },
  { label: "South Sudan", value: "SS", country: true },
  { label: "Spain", value: "ES", country: true },
  { label: "Sri Lanka", value: "LK", country: true },
  { label: "Sudan", value: "SD", country: true },
  { label: "Suriname", value: "SR", country: true },
  { label: "Svalbard and Jan Mayen", value: "SJ", country: true },
  { label: "Swaziland", value: "SZ", country: true },
  { label: "Sweden", value: "SE", country: true },
  { label: "Switzerland", value: "CH", country: true },
  { label: "Syrian Arab Republic", value: "SY", country: true },
  { label: "Taiwan, Province of China", value: "TW", country: true },
  { label: "Tajikistan", value: "TJ", country: true },
  { label: "Tanzania, United Republic of", value: "TZ", country: true },
  { label: "Thailand", value: "TH", country: true },
  { label: "Timor-Leste", value: "TL", country: true },
  { label: "Togo", value: "TG", country: true },
  { label: "Tokelau", value: "TK", country: true },
  { label: "Tonga", value: "TO", country: true },
  { label: "Trinidad and Tobago", value: "TT", country: true },
  { label: "Tunisia", value: "TN", country: true },
  { label: "Turkey", value: "TR", country: true },
  { label: "Turkmenistan", value: "TM", country: true },
  { label: "Turks and Caicos Islands", value: "TC", country: true },
  { label: "Tuvalu", value: "TV", country: true },
  { label: "Uganda", value: "UG", country: true },
  { label: "Ukraine", value: "UA", country: true },
  { label: "United Arab Emirates", value: "AE", country: true },
  { label: "United Kingdom", value: "GB", country: true },
  { label: "United States", value: "US", country: true },
  { label: "United States Minor Outlying Islands", value: "UM", country: true },
  { label: "Uruguay", value: "UY", country: true },
  { label: "Uzbekistan", value: "UZ", country: true },
  { label: "Vanuatu", value: "VU", country: true },
  { label: "Venezuela, Bolivarian Republic of", value: "VE", country: true },
  { label: "Viet Nam", value: "VN", country: true },
  { label: "Virgin Islands, British", value: "VG", country: true },
  { label: "Virgin Islands, U.S.", value: "VI", country: true },
  { label: "Wallis and Futuna", value: "WF", country: true },
  { label: "Western Sahara", value: "EH", country: true },
  { label: "Yemen", value: "YE", country: true },
  { label: "Zambia", value: "ZM", country: true },
  { label: "Zimbabwe", value: "ZW", country: true },
];

export default RouteRestriction;
