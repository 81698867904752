import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProgressBar from "../components/ProgressBar";
import { Range, States } from "../types";
import { EntitiesProvider } from "./EntitiesContext";
import FileForm from "./FileUpload/FileForm";
import ManageCellModal from "./ManageCellModal";
import useUpdateCellEntity from "src/hooks/useUpdateCellEntity";

import { Workbook, WorkbookInstance } from "@tomerkakou/fortune-sheet-react";
import "@tomerkakou/fortune-sheet-react/dist/index.css";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getExcelCoordinate } from "../libs/utils";
import EntitiesPanel from "./EntitiesPanel";
import FileDataForm from "./FileUpload/FileDataForm";
import ManageTableModal from "./ManageTableModal";
import SaveEntitiesBtn from "./SaveEntities";
// import UpdateSheetsDialog from './UpdateSheetsDialog';
import useSelectRange from "src/hooks/useSelectRange";
import useUpdateTableEntity from "src/hooks/useUpdateTableEntity";
import {
  CellEntity,
  TableEntity,
  Factory,
  Entity,
  Ignore,
  Converter,
} from "../libs/Entities";
import Logo from "src/components/Logo";
import { Field } from "src/libs/Fields";
import { Routes, Transshipment } from "src/libs/Entities/Tables";
import UpdateSheetsDialog from "./FileUpload/UpdateSheetsDialog";

const API_URL = process.env.REACT_APP_API_URL ?? "";

const Dashboard: React.FC = () => {
  // State variables
  const [workBookData, setWorkBookData] = useState<any>(null);
  const [file, setFile] = useState<string>();
  const [fileData, setFileData] = useState<any>();
  const [doc, setDoc] = useState<any>();
  //for loading the document
  const [sheetIndex, setSheetIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [version, setVersion] = useState<number>();
  //Manage cell entity modal
  const [openManageCellModal, setOpenManageCellModal] =
    useState<boolean>(false);
  //Manage table entity modal
  const [openManageTableModal, setOpenManageTableModal] =
    useState<boolean>(false);
  //Update sheets dialog
  const [openUpdateSheetsDialog, setOpenUpdateSheetsDialog] =
    useState<boolean>(false);
  //file data modal
  const [openFileDataModal, setOpenFileDataModal] = useState<boolean>(true);
  //StateVariable
  const [state, setState] = useState<States>("NORMAL");

  //entities tools
  const workBookRef = useRef<WorkbookInstance>(null);
  const [globalEntities, setGlobalEntities] = useState<CellEntity[]>([]);
  const [tableEntities, setTableEntities] = useState<TableEntity[]>([]);

  const [errors, setErrors] = useState<any[]>([]);

  const CellManage = useUpdateCellEntity({ api: workBookRef, state, setState });
  const TableManage = useUpdateTableEntity({ api: workBookRef, setState });
  // Custom hook for selecting range
  const { startSelect, endSelect, field } = useSelectRange({ setState, state });

  const sheetsNames = useMemo(() => {
    if (workBookData?.sheets) {
      const allSheets: string[] = workBookData.sheets
        .filter((sheet: any) => sheet.hide !== 1)
        .map((sheet: any) => sheet.name);
      return allSheets.filter(
        (val) => !tableEntities.some((t) => t.sheetName === val)
      );
    }
    return [];
  }, [workBookData, tableEntities]);

  const autoSave = useCallback(async () => {
    const data = Converter.toJson(tableEntities, globalEntities, false);
    const res = await axios.post(`${API_URL}/document`, {
      name: fileData.fileName,
      version,
      document: {
        data,
        metadata: fileData,
      },
    });
    console.log(res.data);
    setVersion(res.data.version);
  }, [globalEntities, tableEntities, fileData, version]);

  const contextMenuList = (() => {
    if (state === "FIELDS") {
      return ["CHECK"];
    }
    if (
      state === "NORMAL" &&
      CellManage.entity === undefined &&
      TableManage.entity === undefined
    ) {
      return [
        "Routes Table",
        "Transshipment",
        "Add Global Entity",
        "Add Fee",
        "Override Value",
        "Ignore cells",
        "Ignore Route",
      ];
    }
    if (state === "HEADERS" && TableManage.entity && !CellManage.entity) {
      return [...TableManage.entity.getHeadersOptions(), "Override Value"];
    }
    if (
      state === "NORMAL" &&
      (CellManage.entity || TableManage.entity) &&
      !openManageCellModal
    ) {
      return ["updateEntity", "revertChanges"];
    }
    return [];
  })();

  // Load workbook sheet by sheet
  useEffect(() => {
    if (!workBookData) {
      return;
    }
    if (workBookRef.current && sheetIndex < workBookData.sheets.length) {
      workBookRef.current?.updateSheet([
        { ...workBookData.sheets[sheetIndex] },
      ]);
      setSheetIndex((prevSheetIndex) => prevSheetIndex + 1);
    } else {
      const id = workBookData.sheets.filter((s: any) => s.hide !== 1)[0].id;
      workBookRef.current?.activateSheet({ id: id });
      setLoading(false);
    }
  }, [workBookData, sheetIndex]);

  const handleValidationErrors = useCallback(
    (newErrors: any) => {
      if (newErrors.length > 0) {
        toast.info("Validation errors found");
      }
      for (const error of errors) {
        if (
          !newErrors.some(
            (e: any) =>
              e.row === error.row &&
              e.col === error.col &&
              e.sheetIndex === error.sheetIndex
          )
        ) {
          workBookRef.current?.setCellFormat(
            error.row,
            error.col,
            "bg",
            error.color,
            {
              index: error.sheetIndex,
              id: "blahblah",
            }
          );
          workBookRef.current?.removeCellToolTip(error.row, error.col, {
            index: error.sheetIndex,
            id: "blahblah",
          });
        }
      }
      for (const error of newErrors) {
        const { row, col, error_message, type, sheetIndex } = error;
        error.sheetName = workBookData.sheets[sheetIndex].name;
        error.range = { r: row, c: col, re: row, ce: col };
        const cellBg = workBookRef.current?.getCellValue(row, col, {
          index: sheetIndex,
          id: "blahblah",
          type: "bg",
        });
        error.color = cellBg;
        workBookRef.current?.setCellFormat(row, col, "bg", "#ff8a80", {
          index: sheetIndex,
          id: "blahblah",
        });
        workBookRef.current?.setCellToolTip(
          row,
          col,
          `${type} : ${error_message}`,
          { index: sheetIndex, id: "blahblah" }
        );
      }
      setErrors(newErrors);
    },
    [workBookRef, workBookData, errors]
  );

  const handleConvertDoc = useCallback((doc: any) => {
    try {
      console.log(doc);
      const [tables, globals] = Converter.fromJson(doc);
      console.log(tables, globals);
      setTableEntities(tables);
      setGlobalEntities(globals);
      setDoc(undefined);
    } catch (e) {
      console.log(e);
      toast.error("Error converting the document");
      setDoc(undefined);
    }
  }, []);

  const handleFormDataSubmit = useCallback(
    (data: any, action: "LOAD" | "NEW", doc?: any, version?: number) => {
      setFileData(data);
      Field.FILE_TYPE = data.loadType;
      Entity.FILE_TYPE = data.loadType;
      Factory.FILE_TYPE = data.loadType;
      if (action === "LOAD") {
        setDoc(doc);
        setVersion(version);
        if (
          Converter.checkSheetsValidity(
            doc,
            workBookData?.sheets.reduce((acc: any, s: any, index: number) => {
              if (s.hide !== 1) {
                acc[s.name] = index;
              }
              return acc;
            }, {})
          )
        ) {
          handleConvertDoc(doc);
        } else {
          setDoc(doc);
          setOpenUpdateSheetsDialog(true);
        }
      }
    },
    [setFileData, setOpenUpdateSheetsDialog, handleConvertDoc, workBookData]
  );

  // Handle adding entity to context menu
  const handleAddEntityContextMenu = useCallback(
    (
      sheetIndex: number,
      sheetName: string,
      range: Range,
      coordinate: string,
      type: string,
      isTable: boolean,
      global?: boolean
    ) => {
      let override = false;
      let parent = TableManage.entity;
      let name = TableManage.entity ? `header-${type}` : "";
      if (type === "Override Value") {
        parent = tableEntities.find(
          (t) =>
            t.sheetIndex === sheetIndex &&
            t.range.r <= range.r &&
            t.range.re >= range.re &&
            t.range.c <= range.c &&
            t.range.ce >= range.ce
        );
        if (!parent) {
          toast.warning("Override entity must be in a bounds of table");
          return;
        }
        override = true;
        name = "Override Value";
        type = "";
        global = false;
      }
      try {
        const entity = Factory.factory(
          uuidv4(),
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          "OK",
          type,
          global,
          parent,
          override
        );

        if (entity instanceof TableEntity) {
          TableManage.beginUpdate(entity);
          setOpenManageTableModal(true);
          setState("NONE");
        } else if (entity instanceof CellEntity) {
          CellManage.beginUpdate(entity);
          if (!openManageTableModal) {
            setOpenManageCellModal(true);
          }
        }
      } catch (e: any) {
        toast.warning(e.message);
      }
    },
    [
      setOpenManageCellModal,
      CellManage,
      TableManage,
      setOpenManageTableModal,
      tableEntities,
      openManageTableModal,
    ]
  );

  const handleAddEntityIgnore = useCallback(
    (
      sheetIndex: number,
      sheetName: string,
      range: Range,
      coordinate: string,
      route?: boolean
    ) => {
      const parent = tableEntities.find(
        (t) =>
          t.sheetIndex === sheetIndex &&
          t.range.r <= range.r &&
          t.range.re >= range.re &&
          t.range.c <= range.c &&
          t.range.ce >= range.ce
      );
      if (!parent) {
        toast.warning("Ignore entity must be in a bounds of table");
        return;
      }
      if (route) {
        parent.addIgnore(
          new Ignore(
            uuidv4(),
            {
              r: range.r,
              re: range.re,
              c: parent.range.c,
              ce: parent.range.ce,
            },
            "Ignore Route",
            sheetIndex,
            getExcelCoordinate({
              r: range.r,
              re: range.re,
              c: parent.range.c,
              ce: parent.range.ce,
            }),
            sheetName,
            "Ignore",
            "OK",
            false,
            parent
          )
        );
      } else {
        parent.addIgnore(
          new Ignore(
            uuidv4(),
            range,
            "Ignore Cell",
            sheetIndex,
            coordinate,
            sheetName,
            "Ignore",
            "OK",
            false,
            parent
          )
        );
      }
      //only to trigger rerender
      setTableEntities([...tableEntities]);
    },
    [tableEntities, setTableEntities]
  );

  // Handle submitting new entity
  const handleAddCellEntitySubmit = useCallback(
    async (entity: CellEntity) => {
      if (entity.global) {
        setGlobalEntities((prevEntities) => [
          ...prevEntities.filter((ent) => ent.id !== entity.id),
          entity,
        ]);
      } else if (entity.parentTable) {
        if (entity.override) {
          entity.parentTable.addOverride(entity);
        } else if (!entity.override) {
          entity.parentTable.submitHeader(entity);
        }
      }
      autoSave();
    },
    [setGlobalEntities, autoSave]
  );

  // Handle submitting new entity
  const handleAddTableEntitySubmit = useCallback(
    async (entity: TableEntity) => {
      if (!tableEntities.includes(entity)) {
        setTableEntities((prevEntities) => [...prevEntities, entity]);
      }
      autoSave();
    },
    [tableEntities, setTableEntities, autoSave]
  );

  // Handle showing entity
  const handleShowEntity = useCallback(
    /**
     *  selecting the entity range
     *  scrolling to the entity range if the entity is in the active sheet
     *  activating the entity sheet (will trigger scrolling also from inside the package)
     */
    (entity: Entity) => {
      const { range, sheetIndex } = entity;
      if (Number.isNaN(sheetIndex)) {
        //do nothing not a valid sheetIndex
        return;
      }
      const select = {
        row: [range.r, range.re],
        column: [range.c, range.ce],
      };
      try {
        workBookRef.current?.setSelection([select], { index: sheetIndex });
        if (sheetIndex === workBookRef.current?.getActiveSheet().index) {
          workBookRef.current?.scroll({
            targetRow: range.r,
            targetColumn: range.c,
          });
        }
      } catch (e) {
        console.error(e);
      }
      workBookRef.current?.activateSheet({ index: sheetIndex });
    },
    [workBookRef]
  );

  // Handle deleting entity
  const handleDeleteCellEntity = useCallback(
    (entity: CellEntity) => {
      const res = window.confirm(
        "Are you sure you want to delete this entity?"
      );
      if (!res) {
        return;
      }
      for (const table of tableEntities) {
        table.removeHeader(entity);
      }
      if (entity.global) {
        setGlobalEntities((prevEntities) =>
          prevEntities.filter((e) => e.id !== entity.id)
        );
      } else {
        if (entity.override) {
          entity.parentTable?.removeOverride(entity);
        }
        //only to trigger rerender
        setTableEntities([...tableEntities]);
      }
    },
    [tableEntities, setGlobalEntities, setTableEntities]
  );

  const handleDeleteIgnoreEntity = useCallback(
    (entity: Ignore) => {
      const res = window.confirm(
        "Are you sure you want to delete this entity?"
      );
      if (!res) {
        return;
      }
      entity.parentTable?.removeIgnore(entity);
      //only to trigger rerender
      setTableEntities([...tableEntities]);
    },
    [tableEntities, setTableEntities]
  );

  // Handle deleting entity
  const handleDeleteTableEntity = useCallback(
    (entity: TableEntity, ask?: boolean) => {
      if (ask) {
        const res = window.confirm(
          "Are you sure you want to delete this entity?"
        );
        if (!res) {
          return;
        }
      }
      if (entity instanceof Routes) {
        for (const transshipment of tableEntities.filter(
          (t) => t instanceof Transshipment
        )) {
          if ((transshipment as Transshipment).routes === entity) {
            alert(
              `This routes table (${entity.name}) is referenced by a transshipment table(${transshipment.name}) and cannot be deleted`
            );
            return;
          }
        }
      }
      setTableEntities([...tableEntities.filter((e) => e.id !== entity.id)]);
    },
    [tableEntities, setTableEntities]
  );

  const handleEditTableEntity = useCallback(
    (entity: TableEntity, range?: boolean) => {
      if (range) {
        TableManage.beginUpdate(entity);
        setState("NORMAL");
      } else {
        TableManage.beginUpdate(entity);
        setOpenManageTableModal(true);
        setState("NONE");
      }
    },
    [TableManage, setState, setOpenManageTableModal]
  );

  const handleEditCellEntity = useCallback(
    (entity: CellEntity, range?: boolean) => {
      if (range) {
        CellManage.beginUpdate(entity);
        setState("NORMAL");
      } else {
        CellManage.beginUpdate(entity);
        if (!openManageTableModal) {
          setOpenManageCellModal(true);
        }
      }
    },
    [CellManage, openManageTableModal]
  );

  const handleCopyCellEntity = useCallback(
    (entity: CellEntity) => {
      const newEntity = Factory.factory(
        uuidv4(),
        entity.range,
        entity.sheetIndex,
        entity.coordinate,
        entity.sheetName,
        entity.name,
        entity.status,
        entity.type,
        entity.global,
        entity.parentTable,
        entity.override
      ) as CellEntity;
      newEntity.initOverride();
      const field1 = entity.getFields();
      const field2 = newEntity.getFields();
      for (let i = 0; i < field1.length; i++) {
        field2[i].setValue(field1[i].getValue());
      }
      CellManage.beginUpdate(newEntity);
      if (!openManageTableModal) {
        setOpenManageCellModal(true);
      }
    },
    [CellManage, openManageTableModal]
  );

  const ContextValues = {
    globalEntities,
    CellManage,
    handleAddCellEntitySubmit,
    handleEditCellEntity,
    handleDeleteCellEntity,
    handleCopyCellEntity,
    tableEntities,
    TableManage,
    handleAddTableEntitySubmit,
    handleEditTableEntity,
    handleDeleteTableEntity,
    fileData: fileData,
    handleShowEntity,
    state,
    setState,
    startSelect,
    field,
    handleDeleteIgnoreEntity,
  };

  return (
    <>
      {!workBookData ? (
        <FileForm setWorkBookData={setWorkBookData} setFile={setFile} />
      ) : (
        <>
          {loading && (
            <ProgressBar
              value={sheetIndex}
              maxValue={workBookData.sheets.length}
              sx={{
                zIndex: 9999999,
                position: "fixed",
                bgcolor: "background.default",
              }}
            />
          )}
          <EntitiesProvider value={ContextValues}>
            <Box
              sx={{
                boxSizing: "border-box",
                borderBottom: 1,
                borderColor: "#E0E8F0",
                width: 1,
                height: "8vh",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ px: 2, text: "center", color: "#707F98" }}
              >
                <Logo width={104} height={31} />
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1">
                  {workBookData?.info?.name}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} sx={{ px: 1, text: "center" }}>
                <Button
                  size="small"
                  startIcon={<SettingsOutlinedIcon />}
                  sx={{ fontFamily: "Hanken Grotesk", fontWeight: 60 }}
                  onClick={() => setOpenFileDataModal(true)}
                >
                  General information
                </Button>
                <SaveEntitiesBtn
                  file={file!}
                  handleValidationErrors={handleValidationErrors}
                  fileName={workBookData.info.name}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                height: "92vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container height={1}>
                <Grid item xs={2} sx={{ maxHeight: 1, height: 1 }}>
                  <EntitiesPanel sheetsNames={sheetsNames} errors={errors} />
                </Grid>
                <Grid item xs={10} height={1}>
                  <Card sx={{ height: 1, elevation: 4 }}>
                    <CardContent
                      sx={{ height: 1, p: 0, "&:last-child": { pb: 0 } }}
                    >
                      <Box sx={{ height: 1, width: 1 }}>
                        <Workbook
                          data={[workBookData.sheets[0]]}
                          ref={workBookRef}
                          showToolbar={false}
                          cellContextMenu={["hide-row", ...contextMenuList]}
                          sheetTabContextMenu={["hide"]}
                          addEntity={handleAddEntityContextMenu}
                          updateEntity={
                            CellManage.entity
                              ? CellManage.submitUpdate
                              : TableManage.submitUpdate
                          }
                          revertChanges={
                            CellManage.entity
                              ? CellManage.revertChanges
                              : TableManage.revertChanges
                          }
                          submitRange={endSelect}
                          addEntityIgnore={handleAddEntityIgnore}
                          allowEdit={false}
                          contextMenuState={state === "NORMAL" ? "ALL" : "CELL"}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/*Modals rendering */}
              <FileDataForm
                open={openFileDataModal}
                handleClose={() => setOpenFileDataModal(false)}
                handleDataSubmit={handleFormDataSubmit}
                fileName={workBookData.info.name}
              />
              {workBookRef && doc && (
                <UpdateSheetsDialog
                  open={openUpdateSheetsDialog}
                  onClose={() => setOpenUpdateSheetsDialog(false)}
                  handleConvertDoc={handleConvertDoc}
                  doc={doc}
                  sheetsOptions={workBookData?.sheets
                    .map((s: any, index: number) => ({
                      label: s.name,
                      value: index,
                      hide: s.hide,
                    }))
                    .filter((s: any) => s.hide !== 1)}
                />
              )}
              {fileData?.loadType && (
                <>
                  <ManageCellModal
                    open={openManageCellModal}
                    handleClose={() => {
                      setOpenManageCellModal(false);
                      setState("NORMAL");
                    }}
                  />
                  <ManageTableModal
                    open={openManageTableModal}
                    handleClose={() => setOpenManageTableModal(false)}
                  />
                </>
              )}
            </Box>
          </EntitiesProvider>
        </>
      )}
    </>
  );
};

export default Dashboard;
