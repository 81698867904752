import SelectWithArgsInput from "src/components/SelectWithArgsInput";
import { C_W } from "./C_W";
import { ContainerType, ContainerTypeValue } from "./ContainerType";
import { Ensure } from "./Ensure";
import { Field } from "./Field";
import { Height } from "./Height";
import { Inclusive } from "./Inclusive";
import { Length } from "./Length";
import { Total } from "./Total";
import { UnitDistance } from "./UnitDistance";
import { UnitWeight } from "./UnitWeight";
import { VolumetricRatio } from "./VolumetricRatio";
import { Weight } from "./Weight";
import { WeightOrMeasurment } from "./WeightOrMeasurment";
import { Width } from "./Width";

export class Conditions extends Field {
  value: object[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Conditions", required);
    this.value = [];
  }

  public getValue(toJson?: boolean): object[] {
    if (toJson) {
      //converting container type to asaf types
      return this.value.map((v: any) => {
        let res = { ...v, value: { ...v.value } };
        if (
          v.key === "MAX_CONTAINER_WEIGHT" ||
          v.key === "MIN_CONTAINER_WEIGHT"
        ) {
          res.value.container_type = v.value.container_type.map(
            (c: ContainerTypeValue) => c.feeId
          );
        }
        return res;
      });
    }
    return this.value;
  }

  public setValue(value: object[], fromJson?: boolean): void {
    if (fromJson) {
      //converting container type from asaf types
      const newValue = [];
      for (const v of value as any[]) {
        const key = v.key.split("$%$")[1] || v.key;
        const option = this.options.find((o) => o.key === key);
        option.value = {};
        for (const f of option.fields) {
          try {
            if (f.fieldName === "container_type") {
              f.setValue(v.value.container_type, true);
              option.value.container_type = f.getValue();
              continue;
            }
            f.setValue(v.value[f.fieldName]);
            option.value[f.fieldName] = f.getValue();
          } catch (e) {
            //Ignore
          }
        }
        newValue.push(option);
      }
      this.value = newValue;
      return;
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectWithArgsInput
        label={this.label}
        fieldName={this.fieldName}
        options={this.options}
        isMulti
      />
    );
  }

  protected get options(): any[] {
    switch (Field.FILE_TYPE) {
      case "FCL":
        return this.FCL_options;
      case "LCL":
        return this.LCL_options;
      case "AIR":
        return this.AIR_options;
      default:
        return [];
    }
  }
  private FCL_options = [
    {
      key: "MAX_CONTAINER_WEIGHT",
      kind: "MAX_CONTAINER_WEIGHT",
      label: "Max weight",
      parent: this.fieldName,
      fields_name: ["container_type", "weight", "unit", "inclusive", "total"],
      fields: [
        new ContainerType("container_type", true),
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
    {
      key: "MIN_CONTAINER_WEIGHT",
      kind: "MIN_CONTAINER_WEIGHT",
      label: "Min weight",
      parent: this.fieldName,
      fields_name: ["container_type", "weight", "unit", "inclusive", "total"],
      fields: [
        new ContainerType("container_type", true),
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
  ];

  private LCL_options = [
    {
      key: "MAX_WEIGHT",
      kind: "MAX_WEIGHT",
      label: "Max weight",
      parent: this.fieldName,
      fields_name: ["weight", "unit", "inclusive", "total"],
      fields: [
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
    {
      key: "MIN_WEIGHT",
      kind: "MIN_WEIGHT",
      label: "Min weight",
      parent: this.fieldName,
      fields_name: ["weight", "unit", "inclusive", "total"],
      fields: [
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
    {
      key: "HAZARDOUS",
      kind: "HAZARDOUS",
      label: "Hazardous",
      parent: this.fieldName,
      fields_name: ["ensure"],
      fields: [new Ensure("ensure", true)],
    },
    {
      key: "MAX_WIDTH",
      kind: "MAX_WIDTH",
      label: "Max Width",
      parent: this.fieldName,
      fields_name: ["width", "unit", "inclusive"],
      fields: [
        new Width("width", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MIN_WIDTH",
      kind: "MIN_WIDTH",
      label: "Min Width",
      parent: this.fieldName,
      fields_name: ["width", "unit", "inclusive"],
      fields: [
        new Width("width", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MAX_LENGTH",
      kind: "MAX_LENGTH",
      label: "Max Length",
      parent: this.fieldName,
      fields_name: ["length", "unit", "inclusive"],
      fields: [
        new Length("length", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MIN_LENGTH",
      kind: "MIN_LENGTH",
      label: "Min Length",
      parent: this.fieldName,
      fields_name: ["length", "unit", "inclusive"],
      fields: [
        new Length("length", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MAX_WEIGHT_OR_MEASUREMENT",
      kind: "MAX_WEIGHT_OR_MEASUREMENT",
      label: "Max W/M",
      parent: this.fieldName,
      fields_name: ["weight_or_measurement", "inclusive", "volumetric_ratio"],
      fields: [
        new WeightOrMeasurment("weight_or_measurement", true),
        new Inclusive("inclusive", true),
        new VolumetricRatio("volumetric_ratio", true),
      ],
    },
    {
      key: "MIN_WEIGHT_OR_MEASUREMENT",
      kind: "MIN_WEIGHT_OR_MEASUREMENT",
      label: "Min W/M",
      parent: this.fieldName,
      fields_name: ["weight_or_measurement", "inclusive", "volumetric_ratio"],
      fields: [
        new WeightOrMeasurment("weight_or_measurement", true),
        new Inclusive("inclusive", true),
        new VolumetricRatio("volumetric_ratio", true),
      ],
    },
  ];

  private AIR_options = [
    {
      key: "MAX_WEIGHT",
      kind: "MAX_WEIGHT",
      label: "Max weight",
      parent: this.fieldName,
      fields_name: ["weight", "unit", "inclusive", "total"],
      fields: [
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
    {
      key: "MIN_WEIGHT",
      kind: "MIN_WEIGHT",
      label: "Min weight",
      parent: this.fieldName,
      fields_name: ["weight", "unit", "inclusive", "total"],
      fields: [
        new Weight("weight", true),
        new UnitWeight("unit", true),
        new Inclusive("inclusive", false),
        new Total("total", true),
      ],
    },
    {
      key: "HAZARDOUS",
      kind: "HAZARDOUS",
      label: "Hazardous",
      parent: this.fieldName,
      fields_name: ["ensure"],
      fields: [new Ensure("ensure", true)],
    },
    {
      key: "MAX_WIDTH",
      kind: "MAX_WIDTH",
      label: "Max Width",
      parent: this.fieldName,
      fields_name: ["width", "unit", "inclusive"],
      fields: [
        new Width("width", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MIN_WIDTH",
      kind: "MIN_WIDTH",
      label: "Min Width",
      parent: this.fieldName,
      fields_name: ["width", "unit", "inclusive"],
      fields: [
        new Width("width", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MAX_LENGTH",
      kind: "MAX_LENGTH",
      label: "Max Length",
      parent: this.fieldName,
      fields_name: ["length", "unit", "inclusive"],
      fields: [
        new Length("length", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MIN_LENGTH",
      kind: "MIN_LENGTH",
      label: "Min Length",
      parent: this.fieldName,
      fields_name: ["length", "unit", "inclusive"],
      fields: [
        new Length("length", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MAX_HEIGHT",
      kind: "MAX_HEIGHT",
      label: "Max Height",
      parent: this.fieldName,
      fields_name: ["height", "unit", "inclusive"],
      fields: [
        new Height("height", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MIN_HEIGHT",
      kind: "MIN_HEIGHT",
      label: "Min Height",
      parent: this.fieldName,
      fields_name: ["height", "unit", "inclusive"],
      fields: [
        new Height("height", true),
        new UnitDistance("unit", true),
        new Inclusive("inclusive", false),
      ],
    },
    {
      key: "MAX_CHARGEABLE_WEIGHT",
      kind: "MAX_CHARGEABLE_WEIGHT",
      label: "Max C/W",
      parent: this.fieldName,
      fields_name: ["chargeable_weight", "inclusive", "unit"],
      fields: [
        new C_W("chargeable_weight", true),
        new Inclusive("inclusive", true),
        new UnitWeight("unit", true),
      ],
    },
    {
      key: "MIN_CHARGEABLE_WEIGHT",
      kind: "MIN_CHARGEABLE_WEIGHT",
      label: "Min C/W",
      parent: this.fieldName,
      fields_name: ["chargeable_weight", "inclusive", "unit"],
      fields: [
        new C_W("chargeable_weight", true),
        new Inclusive("inclusive", true),
        new UnitWeight("unit", true),
      ],
    },
  ];
}
