import React from "react";

import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import { SelectInput } from "../../components/Inputs";
import MainModal from "../../components/MainModal";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useForm } from "react-hook-form";

interface UpdateSheetsDialogProps {
  open: boolean;
  onClose: () => void;
  sheetsOptions: { label: string; value: number }[];
  doc: any;
  handleConvertDoc: (doc: any) => void;
}

const map = new Map<string, any>();

const UpdateSheetsDialog: React.FC<UpdateSheetsDialogProps> = ({
  open,
  onClose,
  sheetsOptions,
  handleConvertDoc,
  doc,
}) => {
  // eslint-disable-next-line
  const [trigger, setTrigger] = React.useState({});
  const errorTabs = (() => {
    const set = new Set<string>();
    for (const key in doc) {
      for (const entity of doc[key]) {
        if (!sheetsOptions.find((o) => o.label === entity.sheetName)) {
          set.add(entity.sheetName);
        }
      }
    }
    return Array.from(set);
  })();

  const { control, handleSubmit } = useForm<Record<string, any>>({
    shouldUnregister: true,
  });

  const handleIgnore = (tab: string) => {
    //set entity status to IGNORE
    for (const key in doc) {
      doc[key] = doc[key].filter((e: any) => e.sheetName !== tab);
    }
    if (errorTabs.length === 1) {
      onClose();
    }
    setTrigger({});
  };

  const onSubmit = (data: any) => {
    for (const key in doc) {
      for (const entity of doc[key]) {
        if (map.has(entity.sheetName)) {
          entity.sheetIndex = map.get(entity.sheetName).value;
          entity.sheetName = map.get(entity.sheetName).label;
        }
      }
    }
    handleConvertDoc(doc);
    onClose();
  };

  return (
    <MainModal open={open} handleClose={() => {}} sx={{ width: 0.6 }}>
      <Stack spacing={5}>
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WarningAmberIcon sx={{ mr: 2 }} />
          {errorTabs.length} Tabs are not matching the template
        </Typography>
        <Box>
          {errorTabs.map((tab, index) => (
            <Box
              key={index}
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            >
              <Box
                sx={{
                  bgcolor: "#EBF0F5",
                  borderRadius: "3px",
                  text: "center",
                  p: 2,
                }}
              >
                {tab}
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  sx={{ fontWeight: 600 }}
                  onClick={() => handleIgnore(tab)}
                >
                  Ignore
                </Button>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ maxHeight: 0.4 }}
                />
                <SelectInput
                  control={control}
                  fieldName={tab}
                  options={sheetsOptions}
                  rules={{ required: true }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  label="Add to Tab"
                  sx={{ minWidth: 150, fontWeight: 600 }}
                  onChange={(e, value) => map.set(tab, value)}
                />
              </Stack>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleSubmit(onSubmit)}
            sx={{ width: 0.3 }}
            variant="contained"
          >
            Update Tabs
          </Button>
        </Box>
      </Stack>
    </MainModal>
  );
};

export default UpdateSheetsDialog;
