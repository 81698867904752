import {
  CellEntity,
  Condition,
  Entity,
  Factory,
  GeneralFeeFCL,
  Ignore,
  TableEntity,
} from "src/libs/Entities";
import { Routes, Transshipment } from "src/libs/Entities/Tables";
import { ContainerType, Conditions as ConditionsField } from "../Fields";
import { OceanFeeFCL } from "./Headers";

export class Converter {
  public static toJson(
    tables: TableEntity[],
    globals: CellEntity[],
    validate: boolean = true
  ): Record<string, any[]> {
    let hasRoutes = false;
    const res: Record<string, any[]> = {
      tables: [],
      pol: [],
      pod: [],
      validityStart: [],
      validityEnd: [],
      fee: [],
      freeDaysPol: [],
      freeDaysPod: [],
      volumetricRatio: [],
      currency: [],
      condition: [],
      hazardous: [],
      via: [],
      comment: [],
      oceanFee: [],
      transitTime: [],
      ignoreCells: [],
      minimum: [],
      range: [],
      service: [],
      overrideCells: [],
    };

    for (const global of globals) {
      res[Entity.Mapper[global.type]].push(global.toJson());
    }

    const sortedTables = tables.sort((a, b) => {
      if (a instanceof Routes && !(b instanceof Routes)) return -1;
      if (!(a instanceof Routes) && b instanceof Routes) return 1;
      return 0;
    });

    for (const table of sortedTables) {
      if (validate && !table.isValid()) {
        throw new Error(`Table ${table.name} has missing required headers`);
      }
      const headers = table.getHeaders();
      const vs = headers
        .find((header) => header.type === "Validity start")
        ?.getFields()[0]
        .getValue();
      const ve = headers
        .find((header) => header.type === "Validity end")
        ?.getFields()[0]
        .getValue();
      if (validate && ve?.isBefore(vs)) {
        throw new Error(
          `Validity end must be after validity start in table ${table.name}`
        );
      }
      for (const header of headers) {
        if (!header.global) {
          res[Entity.Mapper[header.type]].push(header.toJson());
        }
      }
      for (const ignore of table.ignores) {
        if (
          validate &&
          (ignore.range.r < table.range.r ||
            ignore.range.c < table.range.c ||
            ignore.range.re > table.range.re ||
            ignore.range.ce > table.range.ce)
        ) {
          throw new Error(
            `Ignore at coordinate ${ignore.coordinate} in table ${table.name} is out of bounds`
          );
        }
        res.ignoreCells.push(ignore.toJson());
      }
      for (const override of table.overrides) {
        if (
          validate &&
          (override.range.r < table.range.r ||
            override.range.c < table.range.c ||
            override.range.re > table.range.re ||
            override.range.ce > table.range.ce)
        ) {
          throw new Error(
            `Override Value ${override.type} ${override.coordinate} in table ${table.name} is out of bounds`
          );
        }
        res.overrideCells.push({
          sheetIndex: override.sheetIndex,
          sheetName: override.sheetName,
          row: override.range.r,
          col: override.range.c,
          value: override.toJson(),
          tableId: table.id,
          entityType: Entity.Mapper[override.type],
        });
      }

      res.tables.push(table.toJson());

      if (table instanceof Transshipment) {
        if (validate && table.routes === null) {
          throw new Error(
            `Transshipment table ${table.name} must have a routes table`
          );
        }
        const routes = res.tables.find(
          (route) => route.id === table.routes!.id
        );
        if (validate && routes === undefined) {
          table.routes = null;
          throw new Error(
            `Transshipment table ${table.name} must have a routes table`
          );
        }
        routes.dependencies.push({ entityId: table.id, type: "transshipment" });
      } else if (table instanceof Routes) {
        hasRoutes = true;
      }
    }

    if (validate && !hasRoutes) {
      throw new Error("There must be at least one routes table");
    }
    //in fcl ocean fee get duplicates by container type
    res.oceanFee = res.oceanFee.flat();
    //conditions get duplicates by condition key
    res.condition = res.condition.flat();
    return res;
  }

  public static fromJson(
    json: Record<string, any[]>
  ): [TableEntity[], CellEntity[]] {
    const map = new Map<string, Entity>();
    const tables: TableEntity[] = [];
    const globals: CellEntity[] = [];

    const keys = [
      "pol",
      "pod",
      "validityStart",
      "validityEnd",
      "fee",
      "freeDaysPol",
      "freeDaysPod",
      "volumetricRatio",
      "currency",
      "condition",
      "hazardous",
      "via",
      "comment",
      "transitTime",
      "ignoreCells",
      "minimum",
      "range",
      "service",
      "oceanFee",
    ];

    // load cell entities
    for (const key of keys) {
      for (const jsonEntity of json[key]) {
        try {
          let {
            id,
            sheetIndex,
            sheetName,
            metadata: { name, range, coordinate, type, status, global },
          } = jsonEntity;
          global =
            global !== undefined ? global : jsonEntity.scope === "global";
          const entity = Factory.factory(
            id,
            range,
            sheetIndex,
            coordinate,
            sheetName,
            name,
            status,
            type,
            global,
            undefined,
            false
          ) as CellEntity;
          entity.fromJson(jsonEntity, map);
          if (global) {
            globals.push(entity as CellEntity);
          }
          map.set(entity.id, entity);
        } catch (e) {
          console.error(e);
          continue;
        }
      }
    }

    // combine ocean fee entities
    if (Entity.FILE_TYPE === "FCL") {
      const dict: Record<string, string[]> = {};
      for (const { id, type } of json.oceanFee) {
        const key = id.split("$%$")[0];
        if (dict[key] === undefined) {
          dict[key] = [];
        }
        dict[key].push(type);
      }
      for (const key in dict) {
        const ocean = map.get(key) as OceanFeeFCL;
        const containerTypeField = ocean
          .getFields()
          .find((f) => f instanceof ContainerType);
        (containerTypeField as ContainerType)?.setValue(dict[key], true);
      }
    }

    // combine general fee entities
    if (Entity.FILE_TYPE === "FCL") {
      const dict: Record<string, string[]> = {};
      for (const { id, type } of json.fee) {
        const key = id.split("$%$")[0];
        if (dict[key] === undefined) {
          dict[key] = [];
        }
        dict[key].push(type);
      }
      for (const key in dict) {
        const fee = map.get(key) as GeneralFeeFCL;
        const containerTypeField = fee
          .getFields()
          .find((f) => f instanceof ContainerType);
        (containerTypeField as ContainerType)?.setValue(dict[key], true);
      }
    }

    // combine conditions entities
    const dict: Record<string, any[]> = {};
    for (const { id, value } of json.condition) {
      const key = id.split("$%$")[0];
      if (dict[key] === undefined) {
        dict[key] = [];
      }
      dict[key] = dict[key].concat(value);
    }
    for (const key in dict) {
      const cond = map.get(key) as Condition;
      const conditionsField = cond
        .getFields()
        .find((f) => f instanceof ConditionsField);
      (conditionsField as ConditionsField)?.setValue(dict[key], true);
    }

    // load table entities sort to load routes first
    const sortedTables = json.tables.sort((a, b) => {
      if (a.type === "routes" && b.type !== "routes") return -1;
      if (a.type !== "routes" && b.type === "routes") return 1;
      return 0;
    });
    for (const jsonTable of sortedTables) {
      try {
        const {
          id,
          sheetIndex,
          sheetName,
          metadata: { name, range, coordinate, status, type },
        } = jsonTable;
        const table = Factory.factory(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          type
        ) as TableEntity;
        table.fromJson(jsonTable, map);
        tables.push(table);
        map.set(id, table);
      } catch (e) {
        console.error(e);
        continue;
      }
    }

    // attach overrides to tables
    for (const jsonEntity of json.overrideCells) {
      try {
        const {
          id,
          sheetIndex,
          sheetName,
          metadata: { name, range, coordinate, type, status },
        } = jsonEntity.value;
        const table = map.get(jsonEntity.tableId) as TableEntity;
        const entity = Factory.factory(
          id,
          range,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          type,
          false,
          table,
          true
        ) as CellEntity;
        entity.fromJson(jsonEntity.value, map);
      } catch (e) {
        console.error(e);
        continue;
      }
    }

    // attach ignore cells to tables
    for (const jsonEntity of json.ignoreCells) {
      try {
        const {
          id,
          sheetIndex,
          sheetName,
          metadata: { name, range, coordinate, type, status },
        } = jsonEntity;
        const table = map.get(jsonEntity.tableId) as TableEntity;
        const ignore = new Ignore(
          id,
          range,
          type,
          sheetIndex,
          coordinate,
          sheetName,
          name,
          status,
          false,
          table,
          false
        );
        table.addIgnore(ignore);
      } catch (e) {
        console.log(e);
        continue;
      }
    }

    // resetting fees to ratebais precent
    for (const jsonEntity of [...json.oceanFee, ...json.fee]) {
      const entity = map.get(jsonEntity.id);
      if (entity) {
        entity.fromJson(jsonEntity, map);
      }
    }

    // removing duplicates from globals
    return [
      tables,
      globals
        .reverse()
        .filter(
          (g, index, array) => index === array.findIndex((t) => t.id === g.id)
        ),
    ];
  }

  public static checkSheetsValidity(
    json: any,
    currentSheets: Record<string, number>
  ): boolean {
    for (const key in json) {
      for (const jsonEntity of json[key]) {
        const sheetName = jsonEntity.sheetName;
        if (currentSheets[sheetName] !== undefined) {
          jsonEntity.sheetIndex = currentSheets[sheetName];
        } else {
          return false;
        }
      }
    }
    return true;
  }
}
