import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Dashboard from "./dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BORDER_COLOR } from "./libs/colors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tracker from "@openreplay/tracker";
import moment from "moment-timezone";

const tracker = new Tracker({
  projectKey: "xGEPUvgKtbeHwEBWCsyr",
  obscureInputNumbers: false,
  obscureTextEmails: false,
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureTextNumbers: false,
  __DISABLE_SECURE_MODE: true,
  defaultInputMode: 0,
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2C3A57",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#707F98",
            fontFamily: "Hanken Grotesk",
          },
          "& label.Mui-focused": {
            color: "#707F98",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: BORDER_COLOR,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: BORDER_COLOR,
            },
            "&:hover fieldset": {
              borderColor: BORDER_COLOR,
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              borderColor: BORDER_COLOR,
            },
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <HighlightOffIcon sx={{ fontSize: "small" }} />,
        size: "small",
        sx: { color: "#0A2540", bgcolor: "#E0E8F0", borderRadius: "4px" },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: "#2C3A57",
            color: "#FFFFFF",
          },
        },
      },
    },
  },
});

moment.tz.setDefault("UTC");

function App() {
  useEffect(() => {
    tracker.start();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastContainer theme={theme.palette.mode} position="top-center" />
        <Dashboard />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
