import { debounce } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import useEntities from "../dashboard/EntitiesContext";
import { SelectInput, SelectInputProps } from "./Inputs";

const API_URL = process.env.REACT_APP_API_ENUMS_URL ?? "";
const TOKEN = process.env.REACT_APP_WISOR_TOKEN ?? "";

export const optionsSave: Record<string, any[]> = {};

interface SelectInputAPIProps extends SelectInputProps {
  getOptions: (value: any) => any[];
}
const SelectInputAPI: React.FC<SelectInputAPIProps> = (props) => {
  const { fileData } = useEntities();
  const [options, setOptions] = useState<any[]>(
    optionsSave[props.fieldName] ?? []
  );
  const [loading, setLoading] = useState(false);

  //debounce the input to prevent too many requests
  const handleInputChange = debounce(
    (event: React.SyntheticEvent, value: string, reason: string) => {
      if (value && value.length >= 2) {
        (async () => {
          setLoading(true);
          try {
            const response = await axios.get(
              `${API_URL}${props.options[0]
                .replace("{value}", value)
                .replace("{type}", fileData.loadType)}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                },
              }
            );
            const options = props.getOptions(response.data);
            optionsSave[props.fieldName] = options
              .concat(optionsSave[props.fieldName] ?? [])
              .filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
              );
            setOptions(optionsSave[props.fieldName]);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        })();
      }
    },
    500
  );

  return (
    <SelectInput
      {...props}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
    />
  );
};

export default SelectInputAPI;
