import { SelectInput } from "src/components/Inputs";
import { Field } from "./Field";

export class PrecentType extends Field {
  value: string[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Precent Type", required);
    this.value = [];
  }

  public getValue(): string[] {
    return this.value;
  }

  public setValue(value: string[]): void {
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={PrecentType.options}
        control={control}
        rules={{ required: this.required && "Precent Type is mandatory!" }}
      />
    );
  }

  private static options = ["NORMAL", "PURE PROFIT"];
}
