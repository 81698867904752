import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { SelectInput, TextInput } from "src/components/Inputs";
import MainModal from "src/components/MainModal";
import useEntities from "src/dashboard/EntitiesContext";
import axios from "axios";
import LoadDocument from "./LoadDocument";
import { toast } from "react-toastify";

const API_ENUMS_URL = process.env.REACT_APP_API_ENUMS_URL ?? "";
const TOKEN = process.env.REACT_APP_WISOR_TOKEN ?? "";
const API_URL = process.env.REACT_APP_API_URL ?? "";

type FileDataFormProps = {
  handleDataSubmit: (
    data: FormValues,
    action: "LOAD" | "NEW",
    doc?: any,
    version?: number
  ) => void;
  open: boolean;
  handleClose: () => void;
  fileName: string;
};

/**
 * Component for rendering a form to upload a file and handle the submission.
 * @param setWorkBookData - Function to set the workbook data after successful file upload.
 */

type FormValues = {
  fileName: string;
  loadType: string;
  carrier: string;
  freightForwarder: string;
  agent?: string;
  service?: string;
};

const FileDataForm: React.FC<FileDataFormProps> = ({
  handleDataSubmit,
  open,
  handleClose,
  fileName,
}) => {
  const { control, handleSubmit, setValue, reset, getValues } =
    useForm<FormValues>();
  const { fileData } = useEntities();
  const [doc, setDoc] = useState<any>();
  const [freightOptions, setFreightOptions] = useState<string[]>([]);
  const [openLoad, setOpenLoad] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [loadValues, setLoadValues] = useState<any>({
    name: "",
    freightForwarder: "",
    carrier: "",
  });

  const handleOpenLoad = () => {
    setLoadValues({
      name: getValues("fileName"),
      freightForwarder: getValues("freightForwarder"),
      carrier: getValues("carrier"),
    });
    setOpenLoad(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${API_ENUMS_URL}/freight_forwarders`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        setFreightOptions(response.data.items.map((item: any) => item.name));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const editData = useMemo(
    () => Boolean(fileData) || duplicate,
    [fileData, duplicate]
  );

  useLayoutEffect(() => {
    if (fileData) {
      for (const key in fileData) {
        setValue(key as keyof FormValues, fileData[key]);
      }
    } else {
      console.log("fileName", fileName);
      setValue("fileName", fileName);
    }
  }, [fileData, fileName, setValue]);

  const onSubmit = async (data: FormValues) => {
    if (!duplicate) {
      handleDataSubmit(data, "NEW");
    } else {
      handleDataSubmit(data, "LOAD", doc);
      setDuplicate(false);
    }
    handleClose();
  };

  const handleLoadDocumnet = async (
    name: string,
    version: number,
    action: "OPEN" | "DUPLICATE"
  ) => {
    try {
      const response = await toast.promise(
        axios.get(`${API_URL}/document/${name}?version=${version}`),
        {
          pending: "Loading Document...",
          success: "Document loaded successfully",
          error: "Failed to load document",
        }
      );
      if (action === "DUPLICATE") {
        reset({ ...response.data.document.metadata, fileName: "" });
        setDoc(response.data.document.data);
        setDuplicate(true);
        setOpenLoad(false);
      } else {
        setOpenLoad(false);
        handleDataSubmit(
          response.data.document.metadata,
          "LOAD",
          response.data.document.data,
          version
        );
        handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Error loading document!");
    }
  };

  return (
    <>
      <MainModal
        open={open}
        handleClose={() => {}}
        disableMaxWidth
        sx={{ width: 0.55, px: 5 }}
      >
        <Box mx={5}>
          <Typography variant="h6" textAlign="center" mb={3}>
            {duplicate
              ? `Duplicate ${doc.name}`
              : "Good job, Please enter the file parameters"}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                control={control}
                label="File Name"
                fieldName="fileName"
                rules={{ required: "File Name is required" }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                control={control}
                label="Load Type"
                fieldName="loadType"
                options={["FCL", "LCL", "AIR"]}
                readonly={editData}
                rules={{ required: "Load Type is required" }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                control={control}
                label="Carrier"
                fieldName="carrier"
                rules={{ required: "Carrier is required" }}
                options={carrierNames}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                control={control}
                label="Freight Forwarder"
                fieldName="freightForwarder"
                rules={{ required: "Freight Forwarder is required" }}
                options={freightOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput control={control} label="Agent" fieldName="agent" />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                control={control}
                label="Service"
                fieldName="service"
              />
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!editData && (
              <>
                <Button variant="contained" onClick={handleOpenLoad}>
                  Load template
                </Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Create new template
                </Button>
              </>
            )}
            {editData && (
              <Button
                variant={duplicate ? "contained" : "outlined"}
                onClick={handleSubmit(onSubmit)}
              >
                {duplicate ? "Duplicate" : "Save"}
              </Button>
            )}
          </Stack>
        </Box>
      </MainModal>
      <LoadDocument
        handleLoadDocumnet={handleLoadDocumnet}
        open={openLoad}
        handleClose={() => setOpenLoad(false)}
        freightForwarderNames={freightOptions}
        carrierNames={carrierNames}
        values={loadValues}
      />
    </>
  );
};

export const carrierNames = [
  "ACL",
  "Adani logistic",
  "ADMIRAL",
  "Aero Africa",
  "AKKON",
  "Allalouf",
  "Amass",
  "American Airlines",
  "ANL",
  "APL",
  "ARKAS",
  "Asyad Line",
  "Awatec",
  "Blpl Logistics",
  "Blue Water Lines",
  "BMC LINE",
  "BORCHARD",
  "BOXLINE",
  "BWL",
  "CargoSphere",
  "Carrier TBD",
  "CharterLink",
  "CLEVE",
  "CLG",
  "CMA",
  "CMACGM",
  "CNC",
  "Consol Alliance",
  "Contargo",
  "CONTRA CONSOLIDATIONS",
  "Cordelia",
  "COSCO",
  "CSAV Norasia",
  "CSCL",
  "CULINES",
  "CU LINES",
  "Domestic Shipper",
  "DP World",
  "Econship",
  "ECON Shipping",
  "ECU",
  "EMC",
  "EM LINES",
  "Emu Lines",
  "ESL",
  "ESTER",
  "EVERGREEN",
  "Express Rail Link",
  "Fair Freight",
  "FAIR RATES",
  "FAIR TRADE",
  "FPS",
  "General",
  "GLOBELINK",
  "GLOBELINK UNIMAR",
  "Goodrich",
  "GREENCARRIER CONSOLIDATORS",
  "Grimaldi",
  "Hamburg Sud",
  "Hanjin",
  "Hapag-Lloyd",
  "Heung-a Shipping",
  "Hind Terminal",
  "HMM",
  "HUPAC",
  "I-CON",
  "Indus Container Lines",
  "Interasia Lines",
  "Karl Geuther",
  "K Line",
  "KMTC",
  "Lancer",
  "Legend",
  "Litco",
  "Local",
  "Logicall",
  "Maersk",
  "MARKA",
  "MEDKON",
  "Merchant Shipping",
  "Messina Line",
  "MGL",
  "MIHALA",
  "MISC",
  "MNT",
  "MOL",
  "MSC",
  "MTA",
  "NAUKA",
  "NMT",
  "NORDICON",
  "NVO",
  "NVOCC",
  "NYK Line",
  "Oman",
  "ONE",
  "OOCL",
  "OVERSEAS FREIGHTERS",
  "Perma Shipping Line",
  "PIL",
  "Pristine",
  "RCL",
  "SACO",
  "Safmarine",
  "SALAMIS",
  "Samudera",
  "Schneider",
  "Schweizerzug",
  "SCI",
  "SEAGOLD",
  "Sealand",
  "Sea Lead Shipping",
  "SEALINER",
  "SEALOG",
  "SEA MASTER",
  "SEVEN SEAS",
  "SHIPCO",
  "SHOT",
  "SINERGY CARGO",
  "Sinokor",
  "SNL",
  "SSC",
  "TAILWIND",
  "TCC LOGISTICS",
  "TCI",
  "TIRTUR",
  "Transliner",
  "TRANSWORLD GLS",
  "T.S LINES",
  "Turkon",
  "UASC",
  "Unifeeder",
  "Unifeeder Line",
  "Unitex",
  "VANGUARD",
  "VOLTA",
  "Wan Hai Lines",
  "WEC",
  "WHL",
  "Wilhelmsen",
  "Winpax",
  "WOAC",
  "WSHIP",
  "YangMing",
  "YML",
  "ZIM",
];

export default FileDataForm;
