import React from "react";

import { Stack } from "@mui/material";

import RightSideModal from "src/components/RightSideModal";
import { BORDER_COLOR } from "src/libs/colors";
import ManageCellForm from "./ManageCellForm";

interface ManageCellModalProps {
  open: boolean;
  handleClose: () => void;
}

const ManageCellModal: React.FC<ManageCellModalProps> = ({
  open,
  handleClose,
}) => {
  return (
    <>
      <RightSideModal open={open} handleClose={handleClose}>
        <Stack
          spacing={2}
          sx={{ p: 2, border: 1, borderRadius: 1, borderColor: BORDER_COLOR }}
        >
          <ManageCellForm handleClose={handleClose} />
        </Stack>
      </RightSideModal>
    </>
  );
};

export default ManageCellModal;
