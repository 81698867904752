import { WorkbookInstance } from "@tomerkakou/fortune-sheet-react";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { SELECTION_COLOR } from "src/libs/colors";
import { rangeIsTable } from "src/libs/utils";
import { Range, States } from "src/types";
import { TableEntity } from "src/libs/Entities";

interface UpdateEntityProps {
  api: React.RefObject<WorkbookInstance>;
  setState: React.Dispatch<SetStateAction<States>>;
}

const useUpdateTableEntity = ({ api, setState }: UpdateEntityProps) => {
  const [entity, setEntity] = useState<TableEntity>(); // Current entity being updated
  const cellsBg = useRef<string[]>(); // Background colors of cells being updated
  const [previousRange, setPreviousRange] = useState<{
    range: Range;
    sheetIndex: number;
  }>();

  // Also, recolor the cells to the selection color
  useEffect(() => {
    if (entity) {
      const { r, re, c, ce } = entity.range;
      api.current?.setCellFormatByRange(
        "bg",
        SELECTION_COLOR,
        { row: [r, re], column: [c, ce] },
        { index: entity.sheetIndex },
      );
    }
  }, [api, entity]);

  // Effect to update the recolor the cells to original color
  useEffect(() => {
    if (previousRange && cellsBg.current) {
      const { r, re, c, ce } = previousRange.range;
      api.current?.setCellFormatByRange(
        "bg",
        cellsBg.current,
        { row: [r, re], column: [c, ce] },
        { index: previousRange.sheetIndex },
      );
    }
    setPreviousRange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRange, cellsBg, api]);

  // Function to begin updating an entity
  const beginUpdate = useCallback(
    (entity: TableEntity) => {
      if (!api.current) {
        return;
      }
      const { range, sheetIndex } = entity;
      if (!Number.isNaN(sheetIndex)) {
        const rangeBg: string[] = [];

        const cells = api.current?.getCellsByRange(
          { row: [range.r, range.re], column: [range.c, range.ce] },
          { index: sheetIndex },
        );
        cells?.forEach((row) => {
          row?.forEach((cell) => {
            rangeBg.push(cell?.bg ?? "");
          });
        });
        cellsBg.current = rangeBg;
      }
      setEntity(entity);
    },
    [api],
  );

  // Function to submit the update of an entity
  const submitUpdate = useCallback(
    (
      sheetIndex: number,
      sheetName: string,
      range: Range,
      coordinate: string,
    ) => {
      if (!entity) {
        return;
      }
      const isTable = rangeIsTable(
        range,
        api.current?.getCellValue(range.r, range.c, {
          index: sheetIndex,
          id: "blahblah",
          type: "mc",
        }),
      );
      if (!isTable) {
        //entity not from same type
        toast.warning(
          `Entity ${entity.name} is Table (entity type cannot be changed!)`,
        );
        return;
      }

      setPreviousRange({ range: entity.range, sheetIndex: entity.sheetIndex });
      entity.sheetIndex = sheetIndex;
      entity.sheetName = sheetName;
      entity.range = range;
      entity.coordinate = coordinate;

      setEntity(undefined);
      setState("NORMAL");
    },
    [entity, api, setState],
  );

  // Function to revert the changes made to an entity
  const revertChanges = useCallback(() => {
    if (!entity) {
      return;
    }
    setPreviousRange({ range: entity.range, sheetIndex: entity.sheetIndex });
    setEntity(undefined);
    setState("NORMAL");
  }, [entity, setState]);

  // Return the necessary functions and state variables
  return { beginUpdate, submitUpdate, revertChanges, entity };
};

export default useUpdateTableEntity;
