import React, { useState } from "react";

import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";

import { useFormContext, useWatch } from "react-hook-form";
import ArgumentsModal from "./ArgumentsModal";
import { SelectInput } from "./Inputs";
import EditIcon from "@mui/icons-material/Edit";
import { Field } from "src/libs/Fields";

export interface fieldOption {
  key: string;
  label: string;
  fields: Field[];
  parent: string;
  value?: any;
}

interface SelectWithArgsInputProps {
  options: fieldOption[];
  fieldName: string;
  label: string;
  isMulti?: boolean;
  onChange?: (event: any, value: any) => void;
}
const SelectWithArgsInput: React.FC<SelectWithArgsInputProps> = ({
  options,
  fieldName,
  label,
  isMulti,
  onChange,
}) => {
  const [openSecondModal, setOpenSecondModal] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<fieldOption>();

  // eslint-disable-next-line
  const [trigger, setTrigger] = useState<object>({});
  const { control, getValues, setValue } = useFormContext();
  const value = useWatch({ control, name: fieldName });

  const optionClick = (tag: fieldOption) => {
    //open arguments modal on option click
    setCurrentOption(tag);
    setOpenSecondModal(true);
  };

  const handleOptionChange = (
    event: React.SyntheticEvent,
    value: fieldOption[] | fieldOption,
    reason: string,
    details?: any
  ) => {
    //open arguments modal on option select
    if (reason === "selectOption") {
      if (Array.isArray(value)) {
        const selectedOption = value.find(
          (option: fieldOption) => option.key === details.option.key
        );
        if (selectedOption?.fields) {
          const index = value.findIndex(
            (option: fieldOption) => option.key === details.option.key
          );
          value[index].value = undefined;
          if (selectedOption.fields.length > 0) {
            optionClick(value[index]);
          }
        }
      } else {
        const selectedOption = value;
        if (selectedOption?.fields) {
          //copying the option data (all select input using same options array)
          value.parent = details.fieldName;
          value.value = undefined;
          //value = { ...selectedOption, parent: details.fieldName, value: undefined };
          setValue(fieldName, value);
          if (selectedOption.fields.length > 0) {
            optionClick(value);
          } else {
            setTrigger({});
          }
        }
      }
    }
    if (onChange) onChange(event, value);
  };

  const handleCloseArguments = (event?: object, reason?: string) => {
    //handaling closing arguments modal
    if (reason === "backdropClick") {
      //preventing backdrop click
      return;
    }
    if (!currentOption) {
      return;
    }
    if (currentOption.value === undefined) {
      let value = getValues(currentOption.parent as any);
      if (Array.isArray(value)) {
        value = value.filter(
          (option: fieldOption) => option.key !== currentOption.key
        );
      } else {
        if (reason !== "SAVE") {
          if (value?.value) delete value.value;
          value = null;
        }
      }
      setValue(currentOption.parent as any, value);
      onChange && onChange(event, value);
    }
    setOpenSecondModal(false);
    setCurrentOption(undefined);
  };

  const handleSubmitArgument = (values: any) => {
    if (!currentOption) {
      return;
    }

    const value = getValues(currentOption.parent as any);
    if (Array.isArray(value)) {
      const index = value.findIndex(
        (option: fieldOption) => option.key === currentOption.key
      );
      //filtering out empty values
      value[index].value = Object.fromEntries(
        Object.entries(values).filter(([key, value]: any) => value || false)
      );

      //taking only feeId
      value[index].value.precentFeeIds = value[index].value?.precentFeeIds?.map(
        (fee: any) => fee.id ?? fee
      );
    } else {
      value.value = Object.fromEntries(
        Object.entries(values).filter(([key, value]: any) => value || false)
      );
      //taking only feeId
      value.value.precentFeeIds = value?.value?.precentFeeIds?.map(
        (fee: any) => fee.id ?? fee
      );
    }
    // reseting value to include arguments values
    setValue(currentOption.parent as any, value);
    handleCloseArguments({}, "SAVE");
  };

  const endAdornment =
    !isMulti && value?.value && Object.keys(value?.value).length > 0 ? (
      <CustomTooltip
        arrow
        placement="top"
        title={<ChipTooltip value={value?.value} />}
      >
        <IconButton onClick={() => optionClick(value)} size="small">
          <EditIcon sx={{ fontSize: "0.85rem" }} />
        </IconButton>
      </CustomTooltip>
    ) : undefined;

  return (
    <>
      <Box mb={2}>
        <SelectInput
          isMulti={isMulti}
          disableClearable
          options={options}
          getOptionLabel={(option: fieldOption) => option.label}
          getOptionKey={(option: fieldOption) => option.key}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option: fieldOption, index: number) => (
              <CustomTooltip
                key={option.label}
                arrow
                placement="top"
                title={<ChipTooltip value={option.value} />}
              >
                <Chip
                  {...getTagProps({ index })}
                  label={option.label}
                  onClick={
                    option.fields
                      ? () =>
                          optionClick(getValues(option.parent as any)[index])
                      : undefined
                  }
                />
              </CustomTooltip>
            ));
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
          control={control}
          fieldName={fieldName}
          label={label}
          variant="outlined"
          onChange={handleOptionChange}
        />
      </Box>
      {currentOption && (
        <ArgumentsModal
          open={openSecondModal}
          handleClose={handleCloseArguments}
          currentOption={currentOption}
          onSubmit={handleSubmitArgument}
        />
      )}
    </>
  );
};

//custom tooltip for chip component
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: "#2C3A57",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2C3A57",
    color: "#fff",
    fontSize: theme.typography.pxToRem(8),
  },
}));

const ChipTooltip = ({ value }: any) => {
  //tooltip content for chip
  if (!value) {
    return null;
  }

  return (
    <>
      {Object.entries(value).map(
        ([key, value]: any) =>
          key !== "precentFeeIds" && (
            <Typography
              key={key}
              variant="subtitle1"
              sx={{ fontWeight: "semi-bold" }}
            >
              <b>{key} : </b>
              {`${
                key === "rateBasis"
                  ? value.label
                  : key === "container_type"
                  ? value.map((v: any) => v.label)
                  : value
              }`}
            </Typography>
          )
      )}
    </>
  );
};

export default SelectWithArgsInput;
