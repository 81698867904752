import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SelectInput } from "src/components/Inputs";
import { CellEntity, Entity, TableEntity } from "src/libs/Entities";
import { Field, RangeSelection } from "src/libs/Fields";
import useEntities from "./EntitiesContext";

interface ManageCellFormProps {
  handleClose?: () => void;
}

const ManageCellForm: React.FC<ManageCellFormProps> = ({ handleClose }) => {
  const {
    CellManage: { entity, revertChanges, changeType },
    handleAddCellEntitySubmit,
    TableManage,
    tableEntities,
    globalEntities,
    field,
  } = useEntities();

  const methods = useForm();
  const { control, handleSubmit, reset, setValue, getValues, setError } =
    methods;
  const [fieldsRange, setFieldsRange] = useState<Field>();

  useEffect(() => {
    if (field) {
      setFieldsRange(field);
    } else if (fieldsRange) {
      let value = fieldsRange.getValue();
      if (value instanceof RangeSelection) {
        value = value.coordinate;
      }
      setValue(fieldsRange.fieldName, value);
      setFieldsRange(undefined);
    }
  }, [field, fieldsRange, setFieldsRange, setValue]);

  useEffect(() => {
    //setting values and current selected type based on entity
    if (entity) {
      setValue("entityType", entity.type || null);
      setValue("name", entity.name);
      if (entity.type === "General Fee") {
        setValue(
          "tables",
          tableEntities.filter((t) =>
            t.getHeaders().find((h) => h.id === entity.id)
          )
        );
      }
      if (!entity.global) {
        setValue("parentTable", entity.parentTable);
      }
      for (const field of entity.getFields()) {
        let value = field.getValue();
        if (value instanceof RangeSelection) {
          value = value.coordinate;
        }
        setValue(field.fieldName, value);
      }
    }
  }, [entity, reset, setValue, tableEntities]);

  const options = useMemo(() => {
    //generate options for select input based on type selection
    if (!entity) {
      return [];
    }
    if (entity.override) {
      return Entity.overrideOptions;
    }
    if (!entity?.global) {
      return [entity?.type];
    }
    return CellEntity.globals();
  }, [entity]);

  const title =
    entity?.type === undefined || entity.global
      ? "Global Entity"
      : entity.override
      ? "Override Value"
      : entity.type;

  const handleCloseAndReset = (event?: object, reason?: string) => {
    if (reason === "backdropClick") {
      //preventing backdrop click
      return;
    }
    reset();
    //used only for closing modal
    if (handleClose) {
      handleClose();
    }
    revertChanges();
  };
  const handleAddEntity = (values: any) => {
    //submit add entity
    if (!entity) {
      return;
    }
    if (values.name) {
      if (
        entity.global &&
        globalEntities.some((e) => e.name === values.name && e.id !== entity.id)
      ) {
        setError("name", { type: "manual", message: "Name already exists!" });
        return;
      }
      entity.name = values.name;
    }
    if (!entity.global) {
      if (
        entity.parentTable !== values.parentTable &&
        entity.parentTable !== undefined
      ) {
        if (entity.override) {
          entity.parentTable.removeOverride(entity);
        } else {
          entity.parentTable.removeHeader(entity);
        }
      }
      entity.parentTable = values.parentTable;
    }
    //saving form values in data key inside entity
    for (const field of entity.getFields()) {
      field.setValue(values[field.fieldName]);
    }

    //refercning general fee to tables
    if (values.tables) {
      for (const table of tableEntities) {
        if (values.tables.includes(table)) {
          table.submitHeader(entity);
        } else {
          table.removeHeader(entity);
        }
      }
    }
    handleAddCellEntitySubmit(entity);
    handleCloseAndReset();
  };

  const handleTypeChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string,
    details?: any
  ) => {
    if (reason === "clear") {
      reset();
    }
    if (reason === "selectOption") {
      reset();
      changeType(value);
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Hanken Grotesk",
          fontSize: "0.95rem",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <SelectInput
        options={options}
        sx={{
          display: !(entity?.global || entity?.override) ? "none" : undefined,
        }}
        fieldName="entityType"
        label="Type (can change fields below)"
        control={control}
        onChange={handleTypeChange}
        rules={{ required: "Type is mandatory!" }}
        readonly={options.length === 1}
      />
      {entity?.getNameField(control)}
      {entity?.global && entity.type === "General Fee" && (
        <SelectInput
          options={tableEntities.filter((e) => e instanceof TableEntity)}
          getOptionLabel={(option: TableEntity) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.type}
          fieldName="tables"
          label="Apply to table"
          control={control}
          isMulti
        />
      )}
      {!entity?.global && (
        <SelectInput
          options={TableManage.entity ? [TableManage.entity] : tableEntities}
          getOptionLabel={(option: TableEntity) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          fieldName="parentTable"
          label="Reference Table"
          control={control}
          rules={{ required: "Reference Table is mandatory!" }}
        />
      )}
      <Divider />
      <FormProvider {...methods}>
        <Stack spacing={2}>
          {entity?.getFields().map((field, index) => (
            <React.Fragment key={index}>
              {field.render(control, setValue, getValues)}
            </React.Fragment>
          ))}
        </Stack>
      </FormProvider>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={handleCloseAndReset}>Cancel</Button>
        <Button onClick={handleSubmit(handleAddEntity)} sx={{ ms: "auto" }}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default ManageCellForm;
