import React, { useEffect, useState } from "react";

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { SelectInput, TextInput } from "src/components/Inputs";
import MainModal from "src/components/MainModal";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import moment from "moment";
import { useForm, useWatch } from "react-hook-form";
import { BORDER_COLOR } from "src/libs/colors";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL ?? "";

interface FormValues {
  document: string;
  carrier: string;
  freightForwarder: string;
}

interface Document {
  id: string;
  name: string;
  loadType: string;
  carrier: string;
  freightForwarder: string;
  timestamp: moment.Moment;
  version: number;
}

interface LoadDocumentProps {
  handleLoadDocumnet: (
    name: string,
    version: number,
    action: "OPEN" | "DUPLICATE"
  ) => void;
  handleClose: () => void;
  open: boolean;
  carrierNames: string[];
  freightForwarderNames: string[];
  values: {
    name: string;
    freightForwarder: string;
    carrier: string;
  };
}

const LoadDocument: React.FC<LoadDocumentProps> = ({
  handleLoadDocumnet,
  handleClose,
  open,
  carrierNames,
  freightForwarderNames,
  values,
}) => {
  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState<Document[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);

  const { control, setValue } = useForm<FormValues>();
  const document = useWatch({ control, name: "document" });
  const carrier = useWatch({ control, name: "carrier" });
  const freightForwarder = useWatch({ control, name: "freightForwarder" });

  useEffect(() => {
    //setValue('document', values.name);
    setValue("carrier", values.carrier);
    setValue("freightForwarder", values.freightForwarder);
  }, [values, setValue]);

  const handleCloseInner = (event?: object, reason?: string) => {
    //handle closing and reset form
    if (reason === "backdropClick") {
      //preventing backdrop click
      return;
    }
    handleClose();
  };

  useEffect(() => {
    (async () => {
      // fetch documents
      try {
        const reponse = await axios.get(`${API_URL}/documents/names`);
        const docs = reponse.data.document_names;
        for (const doc of docs) {
          doc.timestamp = moment(doc.timestamp);
        }
        setDocuments(docs);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch documents");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let res = documents;
    if (document) {
      res = res.filter((doc) =>
        doc.name.toLowerCase().includes(document.toLowerCase())
      );
    }
    if (carrier) {
      res = res.filter((doc) => doc.carrier === carrier);
    }
    if (freightForwarder) {
      res = res.filter((doc) => doc.freightForwarder === freightForwarder);
    }
    setFilteredDocuments(res);
  }, [documents, document, carrier, freightForwarder]);

  // useEffect(()=>{
  //     //debounce the input to prevent too many requests

  //     const deb=debounce((name: string,carrier:string,freightForwarder:string) => {
  //             (async () => {
  //                 setLoading(true);
  //                 try {
  //                     const params = new URLSearchParams();
  //                     if (name) {
  //                         params.append('q', name);
  //                     }
  //                     if (freightForwarder) {
  //                         params.append('f', freightForwarder);
  //                     }
  //                     if (carrier) {
  //                         params.append('c', carrier);
  //                     }
  //                     const response = await axios.get(`${API_URL}/documents/names?${params.toString()}`);
  //                     const docs = response.data.document_names;
  //                     for(const doc of docs){
  //                         doc.timestamp=moment(doc.timestamp)
  //                     }
  //                     setDocuments(prev=>Array.from(new Map([...prev,...docs].map(item => [item.name, item])).values()))

  //                 } catch (error) {
  //                     //do nothing
  //                 } finally {
  //                     setLoading(false);
  //                 }
  //             })();
  //     }, 500)
  //     if(deb){
  //         deb(document,carrier,freightForwarder)
  //     }
  // }, [setDocuments, setLoading,document,carrier,freightForwarder]);

  return (
    <>
      <MainModal
        open={open}
        handleClose={handleCloseInner}
        sx={{
          maxWidth: "70vw",
          width: "70%",
          px: 5,
          py: 3,
          overflow: "hidden",
          minHeight: 0.6,
          maxHeight: 0.6,
        }}
        contentSx={{ overflow: "hidden" }}
      >
        <Stack spacing={4}>
          <Stack
            direction="row"
            sx={{ display: "flex", alignItems: "center" }}
            spacing={1}
          >
            <IconButton onClick={handleClose}>
              <ArrowBackIosNewIcon sx={{ fontSize: "medium" }} />
            </IconButton>
            <Typography sx={{ fontSize: "1.2rem" }}>Load template</Typography>
          </Stack>
          <Stack spacing={2} direction="row" sx={{ width: 0.7 }}>
            <TextInput
              control={control}
              fieldName="document"
              label={
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <SearchIcon />
                  <Typography>Search</Typography>
                </Stack>
              }
              InputProps={{
                endAdornment: loading ? <CircularProgress size={20} /> : null,
              }}
            />
            <SelectInput
              control={control}
              fieldName="carrier"
              label="Shipping Company"
              options={carrierNames}
              sx={{ width: 0.7 }}
            />
            <SelectInput
              control={control}
              fieldName="freightForwarder"
              label="Freight Forwarder"
              options={freightForwarderNames}
              sx={{ width: 0.7 }}
            />
          </Stack>
          <TableContainer
            component={Paper}
            sx={{ border: 1, borderColor: BORDER_COLOR, maxHeight: "39vh" }}
          >
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableBody>
                {filteredDocuments.map((doc) => (
                  <TableRow
                    key={doc.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      fontFamily: "Hanken Grotesk",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 600, textOverflow: "ellipsis" }}
                    >
                      {doc.name}
                    </TableCell>
                    <TableCell align="right">{doc.carrier}</TableCell>
                    <TableCell align="right">{doc.freightForwarder}</TableCell>
                    <TableCell align="right">
                      {doc.timestamp.format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="right">{doc.loadType}</TableCell>
                    <ActionCells
                      versions={doc.version}
                      handleLoadDocumnet={handleLoadDocumnet}
                      name={doc.name}
                    />
                  </TableRow>
                ))}
                {filteredDocuments.length === 0 &&
                  loading &&
                  Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </TableCell>
                    </TableRow>
                  ))}
                {filteredDocuments.length === 0 && !loading && (
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={7}>
                      <Typography variant="h6" textAlign="center">
                        No documents found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </MainModal>
    </>
  );
};

const ActionCells = ({ name, versions, handleLoadDocumnet }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control, handleSubmit } = useForm<{ version: number }>({
    defaultValues: { version: versions },
  });
  const version = useWatch({ control, name: "version" });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = (action: "OPEN" | "DUPLICATE") => {
    return ({ version }: { version: number }) => {
      handleLoadDocumnet(name, version, action);
      setAnchorEl(null);
    };
  };
  return (
    <>
      <TableCell>
        <SelectInput
          control={control}
          fieldName="version"
          label="Version"
          options={Array.from({ length: versions }).map(
            (_, index) => versions - index
          )}
          disableClearable
          getOptionLabel={(option) => option.toString()}
          rules={{ required: true }}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon sx={{ fontSize: "medium" }} />
        </IconButton>
      </TableCell>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              p: 1,
            },
          },
        }}
      >
        <MenuItem
          onClick={handleSubmit(onSubmit("OPEN"))}
          sx={{
            border: 1,
            borderColor: BORDER_COLOR,
            p: 1,
            mb: 1,
            borderRadius: "3px",
          }}
        >
          Open
        </MenuItem>
        <MenuItem
          onClick={handleSubmit(onSubmit("DUPLICATE"))}
          sx={{
            border: 1,
            borderColor: BORDER_COLOR,
            p: 1,
            mb: 1,
            borderRadius: "3px",
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          component="a"
          href={`${API_URL}/document/${name}/download?version=${version}`}
          sx={{
            border: 1,
            borderColor: BORDER_COLOR,
            p: 1,
            mb: 1,
            borderRadius: "3px",
          }}
        >
          Download
        </MenuItem>
      </Menu>
    </>
  );
};

export default LoadDocument;
