import SelectInputAPI, { optionsSave } from "src/components/SelectInputAPI";
import { Field } from "./Field";

export class Port extends Field {
  value: object[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Port", required);
    this.value = [];
  }

  public getValue(): object[] {
    return this.value;
  }

  public setValue(value: any[], fromJson?: boolean): void {
    if (fromJson) {
      optionsSave[this.fieldName] = value
        .concat(optionsSave[this.fieldName] ?? [])
        .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectInputAPI
        label={this.label}
        fieldName={this.fieldName}
        options={["/suggestion?address={value}&quote_method={type}"]}
        control={control}
        rules={{ required: this.required && "Port is mandatory!" }}
        getOptionLabel={(option: any) => option.label}
        isOptionEqualToValue={(option: any, value: any) =>
          option.value === value.value
        }
        getOptions={(value: any) =>
          value.results.seaports.map((item: any) => ({
            label: item.formatted_address,
            value: item.port_code,
          }))
        }
        isMulti
        checkBoxs
      />
    );
  }
}
