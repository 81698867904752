import { Range } from "src/types";
import { FreeDays, RouteRestriction, Field } from "../../Fields";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";
import { Entity } from "../Entity";

export class FreeDaysPOD extends CellEntity {
  private free_days: FreeDays;
  private route_restrictions: RouteRestriction;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Free Days(POD)",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.free_days = new FreeDays("free_days", true);
    this.route_restrictions = new RouteRestriction("route_restrictions", false);
  }

  public getFields(): Field[] {
    return [this.free_days, this.route_restrictions];
  }

  public getNameField(control: any): React.ReactNode {
    return this.global ? super.getNameField(control) : null;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      value: [this.free_days.getValue()],
      routeRestrictions: this.route_restrictions.getValue(),
    };
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.free_days.setValue(json.value[0]);
    this.route_restrictions.setValue(json.routeRestrictions);
  }
}
