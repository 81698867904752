import { ContainerType, ContainerTypeValue, Field } from "src/libs/Fields";
import { Range } from "src/types";
import { TableEntity } from "../../TableEntity";
import { OceanFee } from "./OceanFee";
import { Entity } from "../../Entity";

export class OceanFeeFCL extends OceanFee {
  private container_type: ContainerType;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      parentTable,
      override
    );
    this.fee_code.setValue("OCEAN");
    this.fee_name.setValue("Ocean Freight");
    this.container_type = new ContainerType("container_type", true);
    this.comment.setValue("Per Container");
    this.Leg.setValue("International");
  }

  public getFields(): Field[] {
    const res = super.getFields();
    res.splice(4, 0, this.container_type);
    return res;
  }

  public toJson(): any {
    return this.container_type.getValue().map((value: ContainerTypeValue) => ({
      ...super.toJson(),
      id: `${this.id}$%$${value.conditionId}`,
      type: value.label,
      conditionsId: value.conditionId,
      conditionsValue: value.conditionValue,
      feeId: value.feeId,
    }));
  }

  public toDepndency(): any {
    return this.container_type.getValue().map((value: ContainerTypeValue) => ({
      entityId: `${this.id}$%$${value.conditionId}`,
      type: Entity.Mapper[this.type],
    }));
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.id = json.id.split("$%$")[0];
  }
}
