import { Range } from "./../types";

// Function to check if the range is a table
export function rangeIsTable(range: Range, mergeCells: any): boolean {
  if (range.r === range.re /*|| range.c===range.ce*/) {
    return false;
  }
  if (mergeCells === null) {
    //table shape and no mergeCells
    return true;
  }
  const { r, c, rs, cs } = mergeCells;
  return !(
    range.r === r &&
    range.re === r + rs - 1 &&
    range.c === c &&
    range.ce === c + cs - 1
  ); // when false its not a single merge
}

export function getExcelCoordinate({ r, re, c, ce }: any): string {
  function colNumToLetter(num: number): string {
    let letter = "";
    while (num >= 0) {
      letter = String.fromCharCode((num % 26) + 65) + letter;
      num = Math.floor(num / 26) - 1;
    }
    return letter;
  }

  const startCol = colNumToLetter(c);
  const endCol = colNumToLetter(ce);
  const startRow = r + 1;
  const endRow = re + 1;

  if (r === re && c === ce) {
    return `${startCol}${startRow}`;
  } else {
    return `${startCol}${startRow}:${endCol}${endRow}`;
  }
}
