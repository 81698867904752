import { CellEntity } from "./CellEntity";

export class Ignore extends CellEntity {
  public toJson(): any {
    return {
      id: this.id,
      sheetIndex: this.sheetIndex,
      sheetName: this.sheetName,
      startRow: this.range.r,
      startCol: this.range.c,
      endRow: this.range.re,
      endCol: this.range.ce,
      tableId: this.parentTable?.id,
      metadata: {
        type: this.type,
        name: this.name,
        status: this.status,
        coordinate: this.coordinate,
        range: this.range,
      },
    };
  }
}
