import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { styled } from "@mui/material/styles";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { UseTreeItem2Parameters } from "@mui/x-tree-view/useTreeItem2";
import * as React from "react";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { TreeItem2Icon, TreeItem2Provider } from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { useEffect, useState } from "react";
import useEntities from "src/dashboard/EntitiesContext";
import { CellEntity, Entity, TableEntity } from "src/libs/Entities";

export type ExtendedTreeItemProps = {
  id: string;
  label: string;
  type: "TYPE" | "TABLE" | "ENTITY" | "SHEET" | "IGNORE" | "ERROR";
  parentId?: string;
  entity?: Entity;
  allowEdit?: boolean;
  error_message?: string;
};

const TreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: "row",
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
}));

interface TableMenuProps {
  entity: Entity;
  disabled: boolean;
  handleEditTableEntity: (entity: TableEntity, range?: boolean) => void;
  handleDeleteTableEntity: (entity: TableEntity) => void;
}

const TableMenu: React.FC<TableMenuProps> = ({
  entity,
  disabled,
  handleEditTableEntity,
  handleDeleteTableEntity,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (
    func: (entity: TableEntity, range?: boolean) => void,
    param?: any
  ) => {
    return (event: any) => {
      event.stopPropagation();
      setAnchorEl(null);
      func(entity as TableEntity, param);
    };
  };
  return (
    <div>
      <IconButton disabled={disabled} onClick={handleClick} size="small">
        <MoreVertIcon sx={{ fontSize: "medium" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <MenuItem
          onClick={handleClose(handleEditTableEntity)}
          disabled={disabled}
        >
          Edit Data
        </MenuItem>
        <MenuItem
          onClick={handleClose(handleEditTableEntity, true)}
          disabled={disabled}
        >
          Edit Range
        </MenuItem>
        <MenuItem
          onClick={handleClose(handleDeleteTableEntity, true)}
          disabled={disabled}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

interface TreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: TreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const {
    TableManage,
    CellManage,
    handleDeleteCellEntity,
    handleEditCellEntity,
    handleDeleteTableEntity,
    handleEditTableEntity,
    handleShowEntity,
    handleDeleteIgnoreEntity,
    handleCopyCellEntity,
    state,
  } = useEntities();

  const showEntity = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (item.entity) {
      handleShowEntity(item.entity!);
    }
  };

  const item = publicAPI.getItem(itemId);
  let showIcon: boolean = false;
  let disableEdit: boolean = false;
  let disableCopy: boolean = false;
  let cellEntity: CellEntity = {} as CellEntity;
  if ((item.type === "ENTITY" || item.type === "IGNORE") && item.entity) {
    cellEntity = item.entity as CellEntity;
    disableEdit = Boolean(
      CellManage.entity ||
        (TableManage.entity &&
          TableManage.entity.id !== cellEntity.parentTable?.id) ||
        state === "NONE"
    );
    disableCopy = Boolean(
      !cellEntity.override && !cellEntity.global
        ? !cellEntity.parentTable?.allowDuplicate(cellEntity)
        : false
    );
  } else {
    showIcon =
      item.type === "TYPE" &&
      item.parentId !== undefined &&
      TableManage.entity?.id === item.parentId;
  }
  const fontWeight = item.type === "TABLE" || item.type === "SHEET" ? 500 : 400;
  const fontSize =
    item.type === "TABLE" || item.type === "SHEET" ? "0.9rem" : "0.8rem";

  if (item.type === "ERROR") {
    return (
      <TreeItem2Provider itemId={itemId}>
        <TreeItem2Root {...getRootProps(other)} onClick={showEntity}>
          <CustomTooltip title={item.error_message} placement="top" arrow>
            <TreeItemContent {...getContentProps()}>
              <TreeItem2IconContainer {...getIconContainerProps()}>
                <TreeItem2Icon status={status} />
              </TreeItem2IconContainer>
              <Box sx={{ flexGrow: 1, display: "flex", gap: 1 }}>
                <TreeItem2Checkbox {...getCheckboxProps()} />
                <TreeItem2Label
                  {...getLabelProps()}
                  sx={{
                    fontWeight,
                    fontSize,
                    left: item.children?.length === 0 ? -15 : 0,
                  }}
                />
              </Box>
            </TreeItemContent>
          </CustomTooltip>
          {children && (
            <TreeItem2GroupTransition {...getGroupTransitionProps()} />
          )}
        </TreeItem2Root>
      </TreeItem2Provider>
    );
  }
  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)} onClick={showEntity}>
        <TreeItemContent {...getContentProps()}>
          {showIcon && item.children?.length !== 0 && (
            <CheckCircleOutlineIcon
              sx={{ color: "#48AA6B", fontSize: "1.2rem" }}
            />
          )}
          {showIcon && item.children?.length === 0 && (
            <RadioButtonUncheckedIcon
              sx={{ color: "#0A2540", fontSize: "1.2rem" }}
            />
          )}
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <Box sx={{ flexGrow: 1, display: "flex", gap: 1 }}>
            <TreeItem2Checkbox {...getCheckboxProps()} />
            <TreeItem2Label
              {...getLabelProps()}
              sx={{
                fontWeight,
                fontSize,
                left: item.children?.length === 0 ? -15 : 0,
              }}
            />
            {item.type === "TABLE" && item.entity && (
              <TableMenu
                entity={item.entity}
                disabled={Boolean(CellManage.entity || TableManage.entity)}
                handleEditTableEntity={handleEditTableEntity}
                handleDeleteTableEntity={handleDeleteTableEntity}
              />
            )}
          </Box>
          {item.allowEdit && item.type === "ENTITY" && (
            <Stack direction="row">
              <IconButton
                size="small"
                title="Edit data"
                disabled={disableEdit}
                onClick={() => handleEditCellEntity(cellEntity)}
              >
                <EditOutlinedIcon sx={{ fontSize: "medium" }} />
              </IconButton>
              <IconButton
                size="small"
                title="Edit range"
                disabled={disableEdit}
                onClick={() => handleEditCellEntity(cellEntity, true)}
              >
                <SyncOutlinedIcon sx={{ fontSize: "medium" }} />
              </IconButton>
              <IconButton
                size="small"
                title="Duplicate"
                disabled={disableEdit || disableCopy}
                onClick={() => handleCopyCellEntity(cellEntity)}
              >
                <DifferenceOutlinedIcon sx={{ fontSize: "medium" }} />
              </IconButton>
              <IconButton
                size="small"
                title="Delete"
                disabled={disableEdit}
                onClick={() => handleDeleteCellEntity(cellEntity)}
              >
                <DeleteOutlinedIcon sx={{ fontSize: "medium" }} />
              </IconButton>
            </Stack>
          )}
          {item.type === "IGNORE" && (
            <IconButton
              size="small"
              onClick={() => handleDeleteIgnoreEntity(item.entity!)}
            >
              <DeleteOutlinedIcon sx={{ fontSize: "medium" }} />
            </IconButton>
          )}
        </TreeItemContent>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default function TreeView({
  items,
}: {
  items: TreeViewBaseItem<ExtendedTreeItemProps>[];
}) {
  const {
    TableManage: { entity },
    tableEntities,
  } = useEntities();
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[]
  ) => {
    setExpandedItems(itemIds);
  };

  useEffect(() => {
    if (entity) {
      setExpandedItems((prev) =>
        Array.from(
          new Set([
            ...prev,
            `${entity.sheetName}-${entity.sheetIndex}`,
            entity!.id,
          ])
        )
      );
    }
  }, [entity, tableEntities]);

  return (
    <>
      <RichTreeView
        items={items}
        sx={{
          height: "fit-content",
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
        }}
        slots={{ item: CustomTreeItem }}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
      />
    </>
  );
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: "#2C3A57",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2C3A57",
    color: "#fff",
    fontSize: theme.typography.pxToRem(12),
  },
}));
