import SelectFees from "src/components/SelectFees";
import { Field } from "./Field";

export class Fees extends Field {
  value: object[];

  constructor(fieldName: string = "", required: boolean = false) {
    super(fieldName, "Fees", required);
    this.value = [{ routes: [], included: false }];
  }

  public getValue(): object[] {
    return this.value;
  }

  public setValue(value: object[]): void {
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectFees
        control={control}
        fieldName={this.fieldName}
        label={this.label}
        options={[]} // options are overridded inside the component
        isMulti={true}
        rules={{ required: this.required ? "Fees is mandatory!" : undefined }}
      />
    );
  }
}
