import { WorkbookInstance } from "@tomerkakou/fortune-sheet-react";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { SELECTION_COLOR } from "src/libs/colors";
import { CellEntity, Factory } from "src/libs/Entities";
import { rangeIsTable } from "src/libs/utils";
import { Range, States } from "src/types";

interface UpdateEntityProps {
  api: React.RefObject<WorkbookInstance>;
  setState: React.Dispatch<SetStateAction<States>>;
  state: States;
}

const useUpdateCellEntity = ({ api, setState, state }: UpdateEntityProps) => {
  // State variables
  const [entity, setEntity] = useState<CellEntity>();
  const cellsBg = useRef<string[]>();
  const [previousRange, setPreviousRange] = useState<{
    range: Range;
    sheetIndex: number;
  }>();
  const [previousState, setPreviousState] = useState<States>();

  // Also, recolor the cells to the selection color
  useEffect(() => {
    if (entity) {
      const { r, re, c, ce } = entity.range;
      try {
        api.current?.setCellFormatByRange(
          "bg",
          SELECTION_COLOR,
          { row: [r, re], column: [c, ce] },
          { index: entity.sheetIndex },
        );
      } catch (e) {
        console.log(e);
      }
    }
  }, [api, entity]);

  // Effect to update the recolor the cells to original color
  useEffect(() => {
    if (previousRange && cellsBg) {
      const { r, re, c, ce } = previousRange.range;
      try {
        api.current?.setCellFormatByRange(
          "bg",
          cellsBg.current,
          { row: [r, re], column: [c, ce] },
          { index: previousRange.sheetIndex },
        );
      } catch (e) {
        console.log(e);
      }
      cellsBg.current = undefined;
    }
    setPreviousRange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRange, cellsBg, api]);

  // Function to begin updating an entity
  const beginUpdate = useCallback(
    (entity: CellEntity) => {
      if (!api.current) {
        return;
      }
      const { range, sheetIndex } = entity;
      try {
        if (!Number.isNaN(sheetIndex)) {
          const rangeBg: string[] = [];
          const cells = api.current?.getCellsByRange(
            { row: [range.r, range.re], column: [range.c, range.ce] },
            { index: sheetIndex },
          );
          cells?.forEach((row) => {
            row?.forEach((cell) => {
              rangeBg.push(cell?.bg ?? "");
            });
          });
          cellsBg.current = rangeBg;
        }
      } catch (e) {
        console.log(e);
      }
      setEntity(entity);
      setPreviousState(state);
    },
    [api, setPreviousState, state],
  );

  // Function to submit the update of an entity
  const submitUpdate = useCallback(
    (
      sheetIndex: number,
      sheetName: string,
      range: Range,
      coordinate: string,
    ) => {
      if (!entity) {
        return;
      }
      const isTable = rangeIsTable(
        range,
        api.current?.getCellValue(range.r, range.c, {
          index: sheetIndex,
          id: "blahblah",
          type: "mc",
        }),
      );
      if (isTable) {
        //entity not from same type
        toast.warning(
          `Entity  of type ${entity.type} is Cell (entity type cannot be changed!)`,
        );
        return;
      }
      setPreviousRange({ range: entity.range, sheetIndex: entity.sheetIndex });
      entity.sheetIndex = sheetIndex;
      entity.sheetName = sheetName;
      entity.range = range;
      entity.coordinate = coordinate;
      //entity.status = EntityStatus.OK;
      // if(entity.global){
      //     for(const table of tables){
      //       const index=table.headers.findIndex(h=>h.id===entity.id);
      //       if(index!==-1){
      //         table.headers[index]=entity;
      //         break;
      //       }
      //     }
      // }
      setEntity(undefined);
      setState(previousState!);
      setPreviousState(undefined);
    },
    [entity, api, setState, previousState],
  );

  // Function to revert the changes made to an entity
  const revertChanges = useCallback(() => {
    if (!entity) {
      return;
    }
    setPreviousRange({ range: entity.range, sheetIndex: entity.sheetIndex });
    setEntity(undefined);
    setState(previousState!);
    setPreviousState(undefined);
  }, [entity, setPreviousRange, setState, previousState]);

  const changeType = useCallback(
    (type: string) => {
      if (!entity) {
        return;
      }
      const newEntity = Factory.factory(
        entity.id,
        entity.range,
        entity.sheetIndex,
        entity.coordinate,
        entity.sheetName,
        entity.name,
        entity.status,
        type,
        entity.global,
        entity.parentTable,
        entity.override,
      ) as CellEntity;
      newEntity.initOverride();
      setEntity(newEntity);
    },
    [entity],
  );

  // Return the necessary functions and state variables
  return { beginUpdate, submitUpdate, revertChanges, entity, changeType };
};

export default useUpdateCellEntity;
