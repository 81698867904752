import React, { useLayoutEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";

import { fieldOption } from "./SelectWithArgsInput";
import RightSideModal from "./RightSideModal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { BORDER_COLOR } from "src/libs/colors";

/**
 * Props for the ArgumentsModal component.
 */
interface ArgumentsModalProps {
  open: boolean;
  handleClose: () => void;
  currentOption?: fieldOption;
  onSubmit: (values: any) => void;
}

const ArgumentsModal: React.FC<ArgumentsModalProps> = ({
  open,
  handleClose,
  currentOption,
  onSubmit,
}) => {
  const methods = useForm({
    defaultValues: currentOption?.value,
    mode: "onBlur",
  });
  const { control, handleSubmit, setValue, getValues } = methods;

  useLayoutEffect(() => {
    if (currentOption?.value) {
      for (const [key, val] of Object.entries(currentOption.value)) {
        setValue(key, val);
      }
    }
  }, [currentOption, setValue]);

  return (
    <RightSideModal open={open} handleClose={handleClose}>
      <FormProvider {...methods}>
        <Stack
          spacing={2}
          sx={{ p: 2, border: 1, borderRadius: 1, borderColor: BORDER_COLOR }}
        >
          <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
            <Button onClick={handleClose}>
              <ArrowBackIosNewIcon sx={{ fontSize: "medium" }} />
            </Button>
            <Typography
              sx={{
                fontFamily: "Hanken Grotesk",
                fontSize: "0.95rem",
                fontWeight: 600,
              }}
            >
              {currentOption?.label}
            </Typography>
          </Stack>
          {currentOption?.fields.map((field, index) => (
            <React.Fragment key={index}>
              {field.render(control, setValue, getValues)}
            </React.Fragment>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleSubmit(onSubmit)}>Save</Button>
          </Box>
        </Stack>
      </FormProvider>
    </RightSideModal>
  );
};

export default ArgumentsModal;
