import { Range } from "src/types";
import { getExcelCoordinate } from "../utils";

export abstract class Field {
  label: string;
  required: boolean;
  fieldName: string;

  constructor(fieldName: string, label: string, required: boolean = false) {
    this.label = label;
    this.required = required;
    this.fieldName = fieldName;
  }

  public abstract render(
    control: any,
    setValue: any,
    getValues: any
  ): React.ReactNode;
  public abstract getValue(toJson?: boolean): any;
  public abstract setValue(value: any, fromJsom?: boolean): void;

  public static FILE_TYPE = "FCL";
}

export class RangeSelection {
  coordinate: string;
  range: Range;

  constructor(range: Range, coordinate?: string) {
    this.range = range;
    if (!coordinate) {
      this.coordinate = getExcelCoordinate(range);
    } else {
      this.coordinate = coordinate;
    }
  }

  public toJson(): any {
    return {
      startRow: this.range.r,
      endRow: this.range.re,
      startCol: this.range.c,
      endCol: this.range.ce,
    };
  }

  public static fromJson(json: any): RangeSelection {
    return new RangeSelection({
      r: json.startRow,
      re: json.endRow,
      c: json.startCol,
      ce: json.endCol,
    } as Range);
  }
}
