import { Range } from "src/types";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";

export class Comment extends CellEntity {
  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Comment",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      false,
      parentTable,
      override
    );
  }

  public getNameField(control: any): React.ReactNode {
    return null;
  }
}
