import FieldIconSelect from "src/components/FieldIconSelect";
import { SelectInput } from "src/components/Inputs";
import { Field, RangeSelection } from "./Field";

export class Leg extends Field {
  value: string | null | RangeSelection;
  private rangeSelection: boolean;

  constructor(
    fieldName: string,
    required: boolean = false,
    rangeSelection: boolean = false
  ) {
    super(fieldName, "Leg", required);
    this.value = null;
    this.rangeSelection = rangeSelection;
  }

  public getValue(toJson?: boolean): string | null | RangeSelection {
    if (this.value && toJson && !(this.value instanceof RangeSelection)) {
      return Leg.Mapper[this.value];
    }
    return this.value;
  }

  public setValue(
    value: string | null | RangeSelection,
    fromJson?: boolean
  ): void {
    if (fromJson && value && typeof value !== "object") {
      this.value = Leg.Reverse[value] ?? value;
      return;
    }
    if (
      this.value instanceof RangeSelection &&
      value === this.value.coordinate
    ) {
      return; // No change
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    const options = ["Origin Charges", "International", "Destination Charges"];
    if (this.value instanceof RangeSelection) {
      options.push(this.value.coordinate);
    }

    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={options}
        control={control}
        rules={{ required: this.required && "Leg is mandatory!" }}
        InputProps={{
          endAdornment: this.rangeSelection ? (
            <FieldIconSelect field={this} />
          ) : undefined,
        }}
      />
    );
  }

  private static Mapper: Record<string, string> = {
    International: "SHIPPING",
    "Origin Charges": "ORIGIN_CLEARANCE",
    "Destination Charges": "DESTINATION_CLEARANCE",
  };

  private static Reverse: Record<string, string> = {
    SHIPPING: "International",
    ORIGIN_CLEARANCE: "Origin Charges",
    DESTINATION_CLEARANCE: "Destination Charges",
  };
}
