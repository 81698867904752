import React from "react";

interface LogoProps {
  width?: string | number;
  height?: string | number;
}

const Logo: React.FC<LogoProps> = ({ width = "100%", height = "100%" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 203 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1619 38.9369C43.1619 38.9369 51.2461 14.4808 62.4507 3.375C62.4507 3.375 47.8296 5.45637 38.7094 26.9021L38.4224 26.3247C37.8583 25.1902 37.076 24.1778 36.1203 23.3455C35.1645 22.5131 34.054 21.8771 32.8522 21.4738C31.6505 21.0704 30.3809 20.9077 29.1162 20.9947C27.8514 21.0818 26.6162 21.417 25.4811 21.9812L34.2481 39.6034C34.5301 40.1704 34.9211 40.6763 35.3987 41.0923C35.8763 41.5083 36.4313 41.8262 37.0318 42.0278C37.6324 42.2295 38.2668 42.311 38.8989 42.2676C39.531 42.2243 40.1484 42.0569 40.7157 41.7752C41.2831 41.4934 41.7894 41.1027 42.2056 40.6254C42.6219 40.1481 42.94 39.5936 43.1418 38.9934C43.1484 38.9739 43.1548 38.9544 43.1611 38.9349L43.1619 38.9369ZM9.68018 17.3882C10.815 16.8245 12.0498 16.4898 13.3141 16.403C14.5783 16.3163 15.8473 16.4792 17.0486 16.8825C18.2499 17.2858 19.3599 17.9217 20.3152 18.7537C21.2706 19.5857 22.0526 20.5976 22.6166 21.7316L32.3368 41.2734C31.202 41.8371 29.9672 42.1718 28.7029 42.2586C27.4387 42.3454 26.1697 42.1824 24.9684 41.7791C23.7672 41.3758 22.6571 40.74 21.7018 39.9079C20.7464 39.0759 19.9644 38.064 19.4004 36.93L9.68018 17.3882Z"
        fill="#707F98"
      />
      <path
        d="M70.5533 41.4502L63.6062 18.4492H68.1053L72.869 34.9162L77.2689 19.7692H81.5033L85.87 34.9162L90.6669 18.4492H95.1329L88.1857 41.4502H84.0506L79.0222 24.8842H79.7169L74.7216 41.4502H70.5533Z"
        fill="#707F98"
      />
      <path
        d="M97.5641 41.4502V25.1812H101.666V41.4502H97.5641ZM97.4318 22.6732V18.4492H101.832V22.6732H97.4318Z"
        fill="#707F98"
      />
      <path
        d="M111.363 41.7802C110.15 41.7802 109.091 41.6042 108.187 41.2522C107.283 40.9002 106.511 40.4602 105.871 39.9322C105.254 39.4042 104.758 38.8982 104.383 38.4142L107.195 35.9722C107.57 36.5882 108.099 37.1602 108.783 37.6882C109.488 38.1942 110.359 38.4472 111.396 38.4472C112.212 38.4472 112.841 38.2932 113.282 37.9852C113.745 37.6552 113.976 37.2482 113.976 36.7642C113.976 36.3682 113.833 36.0492 113.546 35.8072C113.26 35.5432 112.874 35.3342 112.388 35.1802C111.903 35.0042 111.363 34.8392 110.767 34.6852C110.15 34.5092 109.51 34.3112 108.849 34.0912C108.187 33.8712 107.581 33.5852 107.029 33.2332C106.478 32.8592 106.026 32.3862 105.673 31.8142C105.342 31.2422 105.177 30.5272 105.177 29.6692C105.177 28.2392 105.739 27.0842 106.864 26.2042C107.989 25.3022 109.477 24.8512 111.33 24.8512C112.631 24.8512 113.811 25.1152 114.87 25.6432C115.928 26.1492 116.766 26.8862 117.384 27.8542L114.836 29.8672C114.44 29.2292 113.91 28.7562 113.249 28.4482C112.609 28.1182 111.925 27.9532 111.198 27.9532C110.558 27.9532 110.029 28.0742 109.61 28.3162C109.213 28.5582 109.014 28.8882 109.014 29.3062C109.014 29.5922 109.102 29.8342 109.279 30.0322C109.477 30.2302 109.786 30.4062 110.205 30.5602C110.646 30.6922 111.22 30.8462 111.925 31.0222C112.587 31.1762 113.271 31.3632 113.976 31.5832C114.682 31.8032 115.344 32.1002 115.961 32.4742C116.579 32.8482 117.075 33.3432 117.45 33.9592C117.825 34.5752 118.012 35.3562 118.012 36.3022C118.012 37.3802 117.737 38.3372 117.185 39.1732C116.634 39.9872 115.862 40.6252 114.87 41.0872C113.877 41.5492 112.708 41.7802 111.363 41.7802Z"
        fill="#707F98"
      />
      <path
        d="M128.786 41.7802C127.198 41.7802 125.787 41.4172 124.552 40.6912C123.317 39.9652 122.335 38.9642 121.608 37.6882C120.902 36.3902 120.549 34.9272 120.549 33.2992C120.549 31.6492 120.902 30.1972 121.608 28.9432C122.313 27.6672 123.284 26.6662 124.519 25.9402C125.776 25.2142 127.198 24.8512 128.786 24.8512C130.352 24.8512 131.753 25.2142 132.988 25.9402C134.223 26.6662 135.193 27.6672 135.899 28.9432C136.605 30.1972 136.957 31.6492 136.957 33.2992C136.957 34.9272 136.605 36.3792 135.899 37.6552C135.193 38.9312 134.223 39.9432 132.988 40.6912C131.753 41.4172 130.352 41.7802 128.786 41.7802ZM128.786 38.1832C129.624 38.1832 130.341 37.9852 130.937 37.5892C131.554 37.1932 132.017 36.6322 132.326 35.9062C132.657 35.1802 132.822 34.3112 132.822 33.2992C132.822 32.2652 132.657 31.3962 132.326 30.6922C132.017 29.9662 131.554 29.4162 130.937 29.0422C130.341 28.6462 129.624 28.4482 128.786 28.4482C127.926 28.4482 127.187 28.6462 126.57 29.0422C125.952 29.4162 125.478 29.9662 125.147 30.6922C124.817 31.3962 124.651 32.2652 124.651 33.2992C124.651 34.3112 124.817 35.1802 125.147 35.9062C125.478 36.6322 125.952 37.1932 126.57 37.5892C127.187 37.9852 127.926 38.1832 128.786 38.1832Z"
        fill="#707F98"
      />
      <path
        d="M140.241 41.4502V25.1812H144.376V29.6032L143.748 29.6692C143.814 28.7232 144.101 27.8872 144.608 27.1612C145.115 26.4352 145.733 25.8742 146.461 25.4782C147.21 25.0602 147.971 24.8512 148.743 24.8512C149.295 24.8512 149.813 24.9282 150.298 25.0822C150.805 25.2142 151.324 25.4452 151.853 25.7752L149.868 29.4712C149.625 29.3392 149.317 29.2182 148.942 29.1082C148.567 28.9982 148.192 28.9432 147.817 28.9432C147.376 28.9432 146.946 29.0202 146.527 29.1742C146.13 29.3282 145.766 29.5702 145.435 29.9002C145.126 30.2302 144.873 30.6702 144.674 31.2202C144.586 31.5282 144.509 31.9242 144.443 32.4082C144.398 32.8702 144.376 33.5522 144.376 34.4542V41.4502H140.241Z"
        fill="#707F98"
      />
      <path d="M150.8 41.4502V37.0942H155.167V41.4502H150.8Z" fill="#707F98" />
      <path
        d="M157.225 41.4502L166.488 18.4492H169.697L178.96 41.4502H174.296L168.142 24.7522L161.89 41.4502H157.225ZM163.312 36.9622L164.636 33.1342H171.583L172.972 36.9622H163.312Z"
        fill="#707F98"
      />
      <path
        d="M181.469 41.4502V18.4492H185.736V41.4502H181.469Z"
        fill="#707F98"
      />
    </svg>
  );
};

export default Logo;
