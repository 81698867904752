import SelectWithArgsInput from "src/components/SelectWithArgsInput";
import { Comment } from "./Comment";
import { Currency } from "./Currency";
import { Field } from "./Field";
import { RateBasis } from "./RateBasis";

export class DuplicationRule extends Field {
  value: any[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Duplication Rule", required);
    this.value = [];
  }

  public getValue(toJson?: boolean): any[] {
    if (toJson) {
      return this.value.map((v) => ({
        metadata: v,
        ...v.value,
        ...v.value.rateBasis.value,
        feeType: v.value.rateBasis.feeType,
        rateBasis: undefined,
        key: v.key,
      }));
    }
    return this.value;
  }

  public setValue(value: any[], fromJson?: boolean): void {
    if (fromJson) {
      //converting container type from asaf types
      try {
        const newValue = [];
        for (const vmeta of value as any[]) {
          const v = vmeta.metadata;
          const option = this.options.find((o: any) => o.key === v.key);
          option.value = {};
          for (const f of option.fields) {
            try {
              if (f.fieldName === "rateBasis") {
                f.setValue(v.value.rateBasis, true);
                option.value.rateBasis = f.getValue();
                continue;
              }
              f.setValue(v.value[f.fieldName]);
              option.value[f.fieldName] = f.getValue();
            } catch (e) {
              //Ignore
            }
            option.value = Object.fromEntries(
              Object.entries(option.value).filter(
                ([key, value]: any) => value || false
              )
            );
          }
          newValue.push(option);
        }
        this.value = newValue;
      } catch (e) {
        console.error(e);
      }
      return;
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectWithArgsInput
        label={this.label}
        fieldName={this.fieldName}
        options={this.options}
        isMulti
      />
    );
  }

  private options: any = [
    {
      key: "Keep most expensive",
      label: "Keep most expensive",
      parent: this.fieldName,
      fields: [
        new Currency("currency", true, "Fee currency"),
        new RateBasis("rateBasis", true),
        new Comment("comment", true),
      ],
    },
    {
      key: "summation",
      label: "summation",
      parent: this.fieldName,
      fields: [
        new Currency("currency", true, "Fee currency"),
        new RateBasis("rateBasis", true),
        new Comment("comment", true),
      ],
    },
  ];
}
