import { Range } from "src/types";
import { CellEntity } from "../CellEntity";
import { TableEntity } from "../TableEntity";
import {
  Field,
  Hazardous as HazardousField,
  RouteRestriction,
  SearchKey,
} from "../../Fields";
import { Entity } from "../Entity";
export class Hazardous extends CellEntity {
  private searchKey: SearchKey;
  private hazardous: HazardousField;
  private route_restrictions: RouteRestriction;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    parentTable: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      "Hazardous",
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      false,
      parentTable,
      override
    );
    this.hazardous = new HazardousField("hazardous", false);
    this.route_restrictions = new RouteRestriction("route_restrictions", false);
    this.searchKey = new SearchKey("searchKey", false);
  }

  public getFields(): Field[] {
    return [this.searchKey, this.hazardous, this.route_restrictions];
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      value: [this.hazardous.getValue()],
      searchKey: this.searchKey.getValue(),
      routeRestrictions: this.route_restrictions.getValue(),
    };
  }

  public getNameField(control: any): React.ReactNode {
    return null;
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    super.fromJson(json, map);
    this.hazardous.setValue(json.value[0]);
    this.route_restrictions.setValue(json.routeRestrictions);
    this.searchKey.setValue(json.searchKey);
  }
}
