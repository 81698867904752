import { TreeViewBaseItem } from "@mui/x-tree-view";
import React from "react";
import { TextInput } from "src/components/Inputs";
import { ExtendedTreeItemProps } from "src/components/TreeView";
import { Range } from "src/types";
import { CellEntity } from "./CellEntity";
import { Entity } from "./Entity";
import {
  Condition,
  Currency,
  FreeDaysPOD,
  FreeDaysPOL,
  GeneralFee,
  POD,
  POL,
  ValidityEnd,
  ValidityStart,
} from "./Globals";
import { Ignore } from "./Ignore";

export abstract class TableEntity extends Entity {
  private pol: POL | null;
  private pod: POD | null;
  private conditions: Condition[];
  private currency: Currency | null;
  private validity_start: ValidityStart | null;
  private validity_end: ValidityEnd | null;
  private general_fees: GeneralFee[];
  private free_days_pol: FreeDaysPOL[];
  private free_days_pod: FreeDaysPOD[];
  public overrides: CellEntity[];
  public ignores: Ignore[];

  public constructor(
    id: string,
    range: Range,
    type: string,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE"
  ) {
    super(id, range, type, sheetIndex, coordinate, sheetName, name, status);
    this.pol = null;
    this.pod = null;
    this.conditions = [];
    this.currency = null;
    this.validity_start = null;
    this.validity_end = null;
    this.general_fees = [];
    this.free_days_pol = [];
    this.free_days_pod = [];
    this.overrides = [];
    this.ignores = [];
  }

  public allowDuplicate(header: CellEntity): boolean {
    if (header instanceof POL) {
      return false;
    } else if (header instanceof POD) {
      return false;
    } else if (header instanceof Condition) {
      return true;
    } else if (header instanceof Currency) {
      return false;
    } else if (header instanceof ValidityStart) {
      return false;
    } else if (header instanceof ValidityEnd) {
      return false;
    } else if (header instanceof GeneralFee) {
      return true;
    } else if (header instanceof FreeDaysPOL) {
      return true;
    } else if (header instanceof FreeDaysPOD) {
      return true;
    }
    return false;
  }
  public getHeaders(): CellEntity[] {
    return [
      this.pol,
      this.pod,
      ...this.conditions,
      this.currency,
      this.validity_start,
      this.validity_end,
      ...this.general_fees,
      ...this.free_days_pol,
      ...this.free_days_pod,
    ].filter((cell) => cell !== null) as CellEntity[];
  }

  public getHeadersOptions(): string[] {
    return [
      this.pol ? null : "POL",
      this.pod ? null : "POD",
      "Condition",
      this.currency ? null : "Currency",
      this.validity_start ? null : "Validity start",
      this.validity_end ? null : "Validity end",
      "General Fee",
      "Free Days(POL)",
      "Free Days(POD)",
    ].filter((cell) => cell !== null) as string[];
  }

  public getNameField(control: any): React.ReactNode {
    return (
      <TextInput
        fieldName="name"
        label="Table Name"
        rules={{ required: "Table Name is mandatory!" }}
        control={control}
      />
    );
  }

  public addOverride(override: CellEntity): void {
    this.overrides = [
      ...this.overrides.filter((cell) => cell.id !== override.id),
      override,
    ];
  }

  public addIgnore(ignore: Ignore): void {
    if (!this.ignores.includes(ignore)) {
      this.ignores.push(ignore);
    }
  }

  public removeOverride(override: CellEntity): void {
    this.overrides = this.overrides.filter((cell) => cell !== override);
  }

  public removeIgnore(ignore: Ignore): void {
    this.ignores = this.ignores.filter((entity) => entity !== ignore);
  }

  public submitHeader(header: CellEntity): void {
    if (header instanceof POL) {
      this.pol = header;
    } else if (header instanceof POD) {
      this.pod = header;
    } else if (
      header instanceof Condition &&
      !this.conditions.includes(header)
    ) {
      this.conditions.push(header);
    } else if (header instanceof Currency) {
      this.currency = header;
    } else if (header instanceof ValidityStart) {
      this.validity_start = header;
    } else if (header instanceof ValidityEnd) {
      this.validity_end = header;
    } else if (
      header instanceof GeneralFee &&
      !this.general_fees.includes(header)
    ) {
      this.general_fees.push(header);
    } else if (
      header instanceof FreeDaysPOL &&
      !this.free_days_pol.includes(header)
    ) {
      this.free_days_pol.push(header);
    } else if (
      header instanceof FreeDaysPOD &&
      !this.free_days_pod.includes(header)
    ) {
      this.free_days_pod.push(header);
    }
  }

  public removeHeader(header: CellEntity): void {
    if (header instanceof POL) {
      this.pol = null;
    } else if (header instanceof POD) {
      this.pod = null;
    } else if (header instanceof Condition) {
      this.conditions = this.conditions.filter(
        (condition) => condition !== header
      );
    } else if (header instanceof Currency) {
      this.currency = null;
    } else if (header instanceof ValidityStart) {
      this.validity_start = null;
    } else if (header instanceof ValidityEnd) {
      this.validity_end = null;
    } else if (header instanceof GeneralFee) {
      this.general_fees = this.general_fees.filter((fee) => fee !== header);
    } else if (header instanceof FreeDaysPOL) {
      this.free_days_pol = this.free_days_pol.filter(
        (free_days) => free_days !== header
      );
    } else if (header instanceof FreeDaysPOD) {
      this.free_days_pod = this.free_days_pod.filter(
        (free_days) => free_days !== header
      );
    }
  }

  public getGlobals(): Record<string, any> {
    return {
      pol: this.pol?.global ? this.pol : null,
      pod: this.pod?.global ? this.pod : null,
      conditions: this.conditions.filter((condition) => condition.global),
      currency: this.currency?.global ? this.currency : null,
      validity_start: this.validity_start?.global ? this.validity_start : null,
      validity_end: this.validity_end?.global ? this.validity_end : null,
      general_fees: this.general_fees.filter((fee) => fee.global),
      free_days_pol: this.free_days_pol.filter((free_days) => free_days.global),
      free_days_pod: this.free_days_pod.filter((free_days) => free_days.global),
    };
  }

  public getGlobalsOptions(globals: CellEntity[]): Record<
    string,
    {
      isMulti: boolean;
      label: string;
      options: CellEntity[];
    }
  > {
    return {
      pol: {
        label: "POL",
        isMulti: false,
        options: globals.filter((global) => global instanceof POL),
      },
      pod: {
        label: "POD",
        isMulti: false,
        options: globals.filter((global) => global instanceof POD),
      },
      conditions: {
        label: "Conditions",
        isMulti: true,
        options: globals.filter((global) => global instanceof Condition),
      },
      currency: {
        label: "Currency",
        isMulti: false,
        options: globals.filter((global) => global instanceof Currency),
      },
      validity_start: {
        label: "Validity start",
        isMulti: false,
        options: globals.filter((global) => global instanceof ValidityStart),
      },
      validity_end: {
        label: "Validity end",
        isMulti: false,
        options: globals.filter((global) => global instanceof ValidityEnd),
      },
      general_fees: {
        label: "General Fees",
        isMulti: true,
        options: globals.filter((global) => global instanceof GeneralFee),
      },
      free_days_pol: {
        label: "Free Days (POL)",
        isMulti: true,
        options: globals.filter((global) => global instanceof FreeDaysPOL),
      },
      free_days_pod: {
        label: "Free Days (POD)",
        isMulti: true,
        options: globals.filter((global) => global instanceof FreeDaysPOD),
      },
    };
  }

  public resetGlobals(): void {
    this.pol = !this.pol?.global ? this.pol : null;
    this.pod = !this.pod?.global ? this.pod : null;
    this.currency = !this.currency?.global ? this.currency : null;
    this.validity_start = !this.validity_start?.global
      ? this.validity_start
      : null;
    this.validity_end = !this.validity_end?.global ? this.validity_end : null;
    this.conditions = this.conditions.filter((condition) => !condition.global);
    this.general_fees = this.general_fees.filter((fee) => !fee.global);
    this.free_days_pol = this.free_days_pol.filter(
      (free_days) => !free_days.global
    );
    this.free_days_pod = this.free_days_pod.filter(
      (free_days) => !free_days.global
    );
  }

  public isValid(): boolean {
    return (
      this.pol !== null &&
      this.pod !== null &&
      this.currency !== null &&
      this.validity_start !== null &&
      this.validity_end !== null
    );
  }

  public toTreeItem(): TreeViewBaseItem<ExtendedTreeItemProps> {
    return {
      id: this.id,
      label: this.name,
      type: "TABLE",
      parentId: `${this.sheetName}-${this.sheetIndex}`,
      entity: this,
      children: this.headersToTreeItem(),
    };
  }

  protected headersToTreeItem(): TreeViewBaseItem<ExtendedTreeItemProps>[] {
    const res: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
    res.push({
      id: `${this.id}-pol`,
      label: "POL",
      type: "TYPE",
      parentId: this.id,
      children: this.pol ? [this.pol.toTreeItem(this.id)] : [],
    });
    res.push({
      id: `${this.id}-pod`,
      label: "POD",
      type: "TYPE",
      parentId: this.id,
      children: this.pod ? [this.pod.toTreeItem(this.id)] : [],
    });

    if (this.conditions.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const condition of this.conditions) {
        headerChildren.push(condition.toTreeItem(this.id));
      }
      res.push({
        id: `${this.id}-conditions`,
        label: "Conditions",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }
    res.push({
      id: `${this.id}-currency`,
      label: "Currency",
      type: "TYPE",
      parentId: this.id,
      children: this.currency ? [this.currency.toTreeItem(this.id)] : [],
    });

    res.push({
      id: `${this.id}-validity-start`,
      label: "Validity start",
      type: "TYPE",
      parentId: this.id,
      children: this.validity_start
        ? [this.validity_start.toTreeItem(this.id)]
        : [],
    });

    res.push({
      id: `${this.id}-validity-end`,
      label: "Validity end",
      type: "TYPE",
      parentId: this.id,
      children: this.validity_end
        ? [this.validity_end.toTreeItem(this.id)]
        : [],
    });

    if (this.general_fees.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const fee of this.general_fees) {
        headerChildren.push(fee.toTreeItem(this.id));
      }
      res.push({
        id: `${this.id}-general-fees`,
        label: "General Fees",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }

    if (this.free_days_pol.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const free_days of this.free_days_pol) {
        headerChildren.push(free_days.toTreeItem(this.id));
      }
      res.push({
        id: `${this.id}-free-days-pol`,
        label: "Free Days (POL)",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }
    if (this.free_days_pod.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const free_days of this.free_days_pod) {
        headerChildren.push(free_days.toTreeItem(this.id));
      }
      res.push({
        id: `${this.id}-free-days-pod`,
        label: "Free Days (POD)",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }
    if (this.ignores.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const ignore of this.ignores) {
        headerChildren.push(ignore.toTreeItem(this.id));
      }
      for (const ignore of headerChildren) {
        ignore.type = "IGNORE";
      }
      res.push({
        id: `${this.id}-ignores`,
        label: "Ignores",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }
    if (this.overrides.length > 0) {
      const headerChildren: TreeViewBaseItem<ExtendedTreeItemProps>[] = [];
      for (const override of this.overrides) {
        headerChildren.push(override.toTreeItem(this.id));
        headerChildren[
          headerChildren.length - 1
        ].label = `${override.type} ${override.coordinate}`;
      }
      res.push({
        id: `${this.id}-overrides`,
        label: "Overrides",
        type: "TYPE",
        parentId: this.id,
        children: headerChildren,
      });
    }

    return res;
  }

  public getFees(): CellEntity[] {
    return this.general_fees.filter((fee) => !fee.global);
  }

  public toJson(): any {
    return {
      id: this.id,
      type: Entity.Mapper[this.type],
      sheetName: this.sheetName,
      sheetIndex: this.sheetIndex,
      startRow: this.range.r,
      startCol: this.range.c,
      endRow: this.range.re,
      endCol: this.range.ce,
      dependencies: this.getDependencies().filter(
        (dependency: any) => dependency !== null
      ),
      metadata: {
        name: this.name,
        range: this.range,
        coordinate: this.coordinate,
        type: this.type,
        status: this.status,
      },
    };
  }

  protected getDependencies(): any[] {
    return this.getHeaders()
      .map((header) => header.toDepndency())
      .flat();
  }

  public fromJson(json: any, map: Map<string, Entity>): void {
    for (const dep of json.dependencies) {
      if (dep.type === "transshipment") {
        continue;
      }
      const id = dep.entityId.split("$%$")[0];
      if (map.has(id)) {
        this.submitHeader(map.get(id) as CellEntity);
        (map.get(id) as CellEntity).parentTable = this;
      }
    }
  }
}
