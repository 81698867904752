import {
  Field,
  Minimum,
  RangeSelection,
  VolumetricRatio,
} from "src/libs/Fields";
import { Range } from "src/types";
import { TableEntity } from "../../TableEntity";
import { GeneralFee } from "./GeneralFee";

export class GeneralFeeAIR extends GeneralFee {
  private volumetric_ratio: VolumetricRatio;
  private minimum: Minimum;

  constructor(
    id: string,
    range: Range,
    sheetIndex: number,
    coordinate: string,
    sheetName: string,
    name: string,
    status: "ERROR" | "OK" | "IGNORE",
    global: boolean = false,
    parentTable?: TableEntity,
    override?: boolean
  ) {
    super(
      id,
      range,
      sheetIndex,
      coordinate,
      sheetName,
      name,
      status,
      global,
      parentTable,
      override
    );
    this.volumetric_ratio = new VolumetricRatio("volumetric_ratio", false);
    this.minimum = new Minimum("minimum", false, true);
  }

  public getFields(): Field[] {
    const res = super.getFields();
    res.splice(4, 0, this.volumetric_ratio);
    res.splice(5, 0, this.minimum);
    return res;
  }

  public toJson(): any {
    return {
      ...super.toJson(),
      volumetricRatio: this.volumetric_ratio.getValue(),
      minimum:
        this.minimum.getValue() instanceof RangeSelection
          ? (this.minimum.getValue() as RangeSelection).coordinate
          : this.minimum.getValue(),
      extractFromRange: {
        ...super.toJson().extractFromRange,
        minimum:
          this.minimum.getValue() instanceof RangeSelection
            ? (this.minimum.getValue() as RangeSelection).toJson()
            : undefined,
      },
    };
  }

  public fromJson(json: any, map: Map<string, any>): void {
    super.fromJson(json, map);
    this.volumetric_ratio.setValue(json.volumetricRatio);
    if (json.extractFromRange?.minimum) {
      this.minimum.setValue(
        RangeSelection.fromJson(json.extractFromRange?.minimum)
      );
    } else {
      this.minimum.setValue(json.minimum);
    }
  }
}
