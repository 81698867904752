import { SelectInput } from "src/components/Inputs";
import { Field } from "./Field";

export class Ensure extends Field {
  value: string[];

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Ensure", required);
    this.value = [];
  }

  public getValue(): string[] {
    return this.value;
  }

  public setValue(value: string[]): void {
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={["request_is_hazardous", "request_is_not_hazardous"]}
        control={control}
        rules={{ required: this.required && "Unit is mandatory!" }}
      />
    );
  }
}
