import Slide from "@mui/material/Slide";
import React, { forwardRef } from "react";
import MainModal from "./MainModal";

interface RightSideModalProps {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  buttons?: JSX.Element[];
}

const SlideLeft = forwardRef((props: any, ref) => (
  <Slide timeout={400} ref={ref} direction="left" {...props} />
));

const RightSideModal: React.FC<RightSideModalProps> = ({
  open,
  handleClose,
  children,
  buttons,
}) => {
  return (
    <MainModal
      open={open}
      handleClose={handleClose}
      allowInteraction
      TransitionComponent={SlideLeft}
      hideBackdrop
      sx={{
        width: 0.25,
        height: "92vh",
        maxHeight: "none",
        position: "absolute",
        right: 0,
        bottom: 0,
        margin: 0,
      }}
      buttons={buttons}
      zIndex={1000}
    >
      {children}
    </MainModal>
  );
};

export default RightSideModal;
