import { CheckBoxInput } from "src/components/Inputs";
import { Field } from "./Field";

export class Total extends Field {
  value: boolean;

  constructor(fieldName: string, required: boolean = false) {
    super(fieldName, "Total", required);
    this.value = false;
  }

  public getValue(): boolean {
    return this.value;
  }

  public setValue(value: boolean): void {
    this.value = Boolean(value);
  }
  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    return (
      <CheckBoxInput
        label={this.label}
        fieldName={this.fieldName}
        control={control}
      />
    );
  }
}
