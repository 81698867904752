import FieldIconSelect from "src/components/FieldIconSelect";
import { SelectInput } from "src/components/Inputs";
import { Field, RangeSelection } from "./Field";

export class ViaPort extends Field {
  value: null | RangeSelection;
  private rangeSelection: boolean;

  constructor(
    fieldName: string,
    required: boolean = false,
    rangeSelection: boolean = false
  ) {
    super(fieldName, "VIA Port", required);
    this.value = null;
    this.rangeSelection = rangeSelection;
  }

  public getValue(): null | RangeSelection {
    return this.value;
  }

  public setValue(value: null | RangeSelection | string): void {
    if (
      this.value instanceof RangeSelection &&
      value === this.value.coordinate
    ) {
      return; // No change
    }
    if (typeof value === "string") {
      return;
    }
    this.value = value;
  }

  public render(control: any, setValue: any, getValues: any): React.ReactNode {
    const options = [];
    if (this.value instanceof RangeSelection) {
      options.push(this.value.coordinate);
    }
    return (
      <SelectInput
        label={this.label}
        fieldName={this.fieldName}
        options={options}
        control={control}
        helperText="this field using only for selecting cell"
        rules={{ required: this.required && "VIA Port is mandatory!" }}
        InputProps={{
          endAdornment: this.rangeSelection ? (
            <FieldIconSelect field={this} />
          ) : undefined,
        }}
      />
    );
  }
}
